import "./quote.component.scss";

import React, { FunctionComponent, ReactNode } from "react";

export type QuotePropTypes = {
    left: ReactNode;
    right: ReactNode;
    className?: string
}
export const Quote: FunctionComponent<QuotePropTypes> = ({left, right, className}) => {
    return (
    <div className={`quote full-right white-text ${className || ""}`}>
        <div className="left">{ left }</div>
        <div className="right">{ right }</div>
    </div>);
}