import React from 'react'
import {Spinner as BSSpinner} from 'react-bootstrap'
import styles from './spinner.module.scss'

interface Props {
    isShown?: boolean
}

const Spinner:React.FC<Props> = ({isShown = false}) => {
    if (!isShown) return null
    return (
        <div className={styles.spinnerContainer}>
            <BSSpinner animation='border' />
        </div>
    )
}

export default Spinner
