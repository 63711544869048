import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { setAddress, setInfoWindowText, setMarkerPosition } from '../../cms/redux/actions'
import { center } from '../../constants'

interface Props {
  address: any,
  setAddress: any,
  markerPosition: any,
  setMarkerPosition: any,
  infoWindowText: any,
  setInfoWindowText: any
}

const AutocompletePlaces: React.FC<Props> = ({address, setAddress, setMarkerPosition, setInfoWindowText}) => {

  const searchPlace = useRef<any>(null);
  let autocomplete = useRef<google.maps.places.Autocomplete>();

  useEffect(() => {
    autocomplete.current = new google.maps.places.Autocomplete(searchPlace.current, {
      fields:  ["address_components", "geometry.location", "icon", "name", "formatted_address"],
      types: ['address'],
      componentRestrictions: {country: "us"},
    });
    autocomplete.current.addListener("place_changed", () => {
      if (autocomplete.current !== undefined) {
        const place = autocomplete.current.getPlace();
        onPlaceSelected(place);
      }
    });
    searchPlace.current.addEventListener("keyup", checkAddress);
  },[])

  useEffect(() => {
    searchPlace.current.value = address;
  },[address])

  const checkAddress = () => {
    if (searchPlace.current.value === '') {
      setMarkerPosition(center);
      setInfoWindowText("AirFiber");
    }
  }

  const onPlaceSelected = (place: any) => {
    if (place.address_components) {
      setAddress(place.formatted_address)
      setMarkerPosition(place.geometry.location);
      setInfoWindowText(place.formatted_address);
    }
  }

  return  <div id="pac-container" className="coverage-map-search-container">
    <input ref={searchPlace} type="search" placeholder="Enter a location or click on map" className="coverage-map-search" />
  </div>
}
const mapStateToProps = (state: any) => ({
  address: state.address,
  markerPosition: state.markerPosition,
  infoWindowText: state.infoWindowText
})

export default connect(mapStateToProps, { setAddress, setMarkerPosition, setInfoWindowText })(AutocompletePlaces)
