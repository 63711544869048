import './menu.scss';

import React, { FunctionComponent } from 'react';

import { MENUITEMS } from '../../constants'
import {
  NavLink,
  useLocation
} from 'react-router-dom';

export const Menu: FunctionComponent<{onClick?: () => void}> = ({onClick}) => {

  let location = useLocation();
  const items: JSX.Element[] = MENUITEMS.filter(item => (!location.pathname.match("termofuse") && item.name !== "Terms of Service") ||
    (location.pathname.match("termofuse") && item.name !== "Support")).map((item) =>
    <NavLink key={item.href} exact={item.exact} to={item.href} activeClassName='active'><p onClick={onClick}>{item.name}</p></NavLink>
  );

  return(
      <div className="menu text-normal">
        { items }
      </div>
  )
}