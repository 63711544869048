import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';

export const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return(
        <footer className="footer">
            <div className="footer-title">
                <Link to="/termofuse" style={{textDecorationLine: "underline", fontSize: "22px"}} className="text-big width-80">Terms of Services &amp; Acceptable Use Policy (TOS &amp; AUP)</Link>| Copyright {year} AirFiber WISP
            </div>
            <div className="companies">
            <a target="_blank" rel="noopener noreferrer" href="https://www.netflix.com/">
                <div className="picture netflix"></div>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/Prime-Movies/b?ie=UTF8&node=7613704011">
                <div className="picture amazon"></div>
            </a> 
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/">
                <div className="picture youtube"></div>  
            </a>    
            <a target="_blank" rel="noopener noreferrer" href="https://www.hulu.com/">
                <div className="picture hulu"></div>   
            </a>
            </div>
            {/* <div className="thewhycompany">Designed and developed by 
                <a target="_blank" rel="noopener noreferrer" href="https://www.thewhy.company" className="text-big width-80 link-why">
                    The Why Company
                </a>    
            </div> */}
        </footer>
    )
}