import React from 'react';
import { ILogo } from './logo.props';
import { Link } from 'react-router-dom';


function Logo(props: ILogo) {
  
  return (
    <Link to="/">
      <img className='logo' src="/logo.svg" alt='logo' onClick={ props.onClick } />
    </Link>
  );
}

export default Logo;
