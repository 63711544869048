export default (state = [], action) => {
    switch (action.type) {
        case 'SET_COVERAGES': return action.payload
        case 'ADD_COVERAGE':
            let newId
            if ( state.length === 0) newId = 1
            else newId = state[state.length - 1].id + 1
            const newCoverage = {id: newId, show:'NO', name: '', type: 50, plans: []}
            return [...state, newCoverage]
        case 'UPDATE_COVERAGE': 
            const {idx, key, value} = action.payload
            const updatedCoverage = getUpdatedCoverage(state[idx], key, value)
            state[idx] = updatedCoverage
            return [...state]
        case 'DELETE_COVERAGE':
            const { id } = action.payload
            return state.filter( c => c.id !== id)
        default: return state
    }
}

function getUpdatedCoverage(coverage, field, value) {
    if (field === 'plans') {
        let newCoveragePlans
        if (coverage.plans.includes(value)) newCoveragePlans = coverage.plans.filter(p => p !== value)
        else newCoveragePlans = [...coverage.plans, value]
        return { ...coverage, plans: newCoveragePlans }
    }
    if (field === 'type') {
        if (coverage.poly !== undefined) {
            coverage.poly.fillColor = +value === 100 ? "#FF0000" : "#33FF4E";
        }
    }
    return { ...coverage, [field]: value }
}