import React from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import styles from './feedback-modal.module.scss'

import { connect } from 'react-redux'
import { closeFeedback } from '../redux/actions'

interface Props {
  feedback: any,
  closeFeedback: any
}

const FeedbackModal: React.FC<Props> = ({ feedback, closeFeedback }) => {

  return (
    <Modal show={feedback.isOpen} onHide={() => closeFeedback()}>
      <Modal.Dialog>
        <Modal.Header className={styles.header}>
          <Modal.Title>{feedback.message.title}</Modal.Title>
        </Modal.Header>

        <ModalBody bsPrefix={styles.body}>
          <p>
            {feedback.message.body}
          </p>
        </ModalBody>

        <Modal.Footer className={styles.footer}>
          <Button variant="secondary" onClick={() => closeFeedback()}>Close</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  )
}

const mapStateToProps = (state:any) => {
  return {
    feedback: state.feedback
  }
}

export default connect(mapStateToProps, { closeFeedback })(FeedbackModal)
