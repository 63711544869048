import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom';

import { Landingpage } from '../../containers/landingpage/landingpage';
import { NotFound } from '../not-found/not-found.component';
import LayoutCMS from '../../cms/layout-cms/layout-cms'
import EditCoverage from '../../cms/edit-coverage/edit-coverage'
import EditDataPlans from '../../cms/edit-data-plans/edit-data-plans'
import LoginPage from '../../cms/login-page/login-page'
import { ProtectedRoute } from './protected-route'
import SettingsPage from '../../cms/settings-page'
import PassResetPage from '../../cms/pass-reset-page'
import TestPage from '../../cms/test-page'
import SpeedTest from '../speed-test/speed-test'

export const CheckRouter = () => {
  return(
    <Switch>
      <Route exact path={["/", "/contact","/contact/:type/:speed",  "/business", "/coverage", "/support", "/termofuse"]}>
        <Landingpage />
      </Route>
      <Route exact path="/content-management/coverage">
        <LayoutCMS>
          <ProtectedRoute component={EditCoverage} />
        </LayoutCMS>
      </Route>
      <Route exact path="/content-management/data-plans">
        <LayoutCMS>
          <ProtectedRoute component={EditDataPlans} />
        </LayoutCMS>
      </Route>
      <Route exact path="/content-management/settings">
        <LayoutCMS>
          <ProtectedRoute component={SettingsPage} />
        </LayoutCMS>
      </Route>
      <Route exact path="/pass-reset/:token">
        <PassResetPage />
      </Route>
      <Route path={["/content-management", "/cms"]}>
        <ProtectedRoute component={LayoutCMS} />
      </Route>
      <Route exact path="/login">
        <LoginPage/>
      </Route>
      <Route exact path="/test-page">
        <TestPage/>
      </Route>
      <Route exact path="/speedtest">
        <SpeedTest/>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}
