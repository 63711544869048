import React, { useState, useEffect } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import Spinner from '../custom-spinner/custom-spinner'
import  { FORM_ERROR } from '../snack-bar'
import TableRow from './table-row/table-row'
import styles from './edit-data-plans.module.scss'

import { connect } from 'react-redux'
import { addPlan, postPlans, fetchPlansAndCoverages, triggerSnackBar } from '../redux/actions'

interface Props {
  nrOfPlans: number
  fetchPlansAndCoverages: any
  addPlan: any
  postPlans: any
  triggerSnackBar: any
  isLoading: boolean
}

const EditDataPlans: React.FC<Props> = ({nrOfPlans, isLoading, fetchPlansAndCoverages, addPlan, postPlans, triggerSnackBar}) => {

  const [isValidated, setIsValidated] = useState(false)

  const handleSave = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if ( !form.checkValidity() ) {
      setIsValidated(true)
      triggerSnackBar(FORM_ERROR)
      return
    }

    setIsValidated(false)
    postPlans() 
  }

  useEffect(() => {
    fetchPlansAndCoverages()
  },[fetchPlansAndCoverages]);

  const renderTableBody = () => {
    const indexArr = []
    for ( let i = 0; i < nrOfPlans; i++) {
      indexArr.push(i)
    }
    return (
      <tbody>
        {indexArr.map( i => <TableRow idx={i} key={i + Math.random()} />)}
      </tbody>
    )
  }

  if (isLoading) return <Spinner />


  return (
    <>
      <div className={styles.editPlans}>          
        <h2>Edit Data Plans</h2>
        <Form noValidate validated={isValidated} onSubmit={handleSave}>
          <Table className={styles.table} bordered hover size="sm">
            <thead className={styles.thead}>
              <TableHeaders />
            </thead>
            {renderTableBody()}
          </Table>
          <div className={styles['btn-container']}>
            <Button variant='primary' onClick={addPlan}>Add New Plan</Button>
            <Button variant="success" type='submit'>Save Data</Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const TableHeaders = () => {
  const editFields = [
    'Show',
    'Download',
    'Upload',
    'Price',
    'Type',
    'Disclaimer',
    'Remove'
  ]
  return (
    <tr className={styles.tr}>
      {editFields.map(f => (
        <th key={f} style={{padding: '10px'}}>{f}</th>
      ))}
    </tr>
  )
}

const mapStateToProps = (state: any) => ({
  nrOfPlans: state.nrOfPlans,
  isLoading: state.planLoading
})

export default connect(mapStateToProps, {addPlan, postPlans, fetchPlansAndCoverages, triggerSnackBar })(EditDataPlans)
