import React, { FormEvent, useEffect, useState } from 'react'
import { Button, Container, Col, Row, Form } from 'react-bootstrap'
import {withRouter, useParams, Redirect} from 'react-router-dom'
import SnackBar from '../snack-bar'
import { connect } from 'react-redux'
import { triggerSnackBar } from '../redux/actions'
import { FORM_ERROR, PASS_RESET } from '../snack-bar/snack-bar-messages'
import logo from '../../assets/svg/logo.svg'
import styles from './pass-reset-page.module.scss'
import { API_URL, requestOptions } from '../../constants'

interface PropTypes {
  triggerSnackBar?: any
  history?: any
}

const PassResetPage: React.FC<PropTypes> = ({triggerSnackBar, history}) => {
  const emptyInput = { value: '', feedback: '' }
  const [newPassword, setNewPassword] = useState(emptyInput)
  const [confirmPassword, setConfirmPassword] = useState(emptyInput)
  //const [isValidated, setIsValidated] = useState(false)
  const [isValidToken, setIsValidToken] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { token } = useParams()

  useEffect(() => {
    if (isValidToken === '') {
      const options = { ...requestOptions }
      options.body = JSON.stringify({ token: token })
      fetch(API_URL + "/cms/validate-reset-token", options)
        .then(res => res.json())
        .then(data => {
          if (data.code === 200) {
            setIsValidToken("OK")
          } else {
            setIsValidToken("INVALID")
          }
        })
    }
  })

  const isFormValid = () => {
    let hasValidationErr = false
    if (newPassword.value.length < 8) {
      setNewPassword({...newPassword, feedback: styles.visible})
      hasValidationErr = true
    }

    if (confirmPassword.value !== newPassword.value) {
      setConfirmPassword({...confirmPassword, feedback: styles.visible})
      hasValidationErr = true
    }
    return !hasValidationErr
  }

  const clearForm = () => {
    setNewPassword({value: '', feedback: ''})
    setConfirmPassword({value: '', feedback: ''})
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if ( !isFormValid() ) return triggerSnackBar(FORM_ERROR)
    clearForm()
    const options = {...requestOptions}
    options.body = JSON.stringify({ newPassword: newPassword.value, token: token })
    fetch(API_URL + "/cms/set-new-password", options).then(() => setIsPasswordChanged(true))
    // TODO -> set new password request
    // TODO -> if request is bad, display an error message using the snackbar or the feedback modal
    // TODO -> if request returns status 200-299 toggle confirmation snackbar and redirect to login page after timeout
    triggerSnackBar(PASS_RESET)
    setTimeout(() => history.push('/login'), 3000)
  }

  return isValidToken === "" ? <></> : isValidToken !== "OK" || isPasswordChanged ? <Redirect to={{ pathname: "/login"}}/> : <Container className={`p-5 ${styles.passResetPage}`}>
      <Row>
        <Col sm="12">
          <img alt='' src={logo} className={styles.logo}/>
        </Col>
      </Row>
      <Form className={styles.form} onSubmit={handleSubmit}>
      <Form.Group className={styles.formGroup} as={Row} controlId="newPassword">
        <Form.Label column sm="5" className={styles.label}>
          New Password:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="password"
            placeholder="Enter Password"
            value={newPassword.value}
            onChange={e => setNewPassword({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${newPassword.feedback}`} >Password must be at least 8 characters long.</p>
        </Col>
      </Form.Group>
      <Form.Group className={styles.formGroup} as={Row} controlId="confirmPassword">
        <Form.Label column sm="5" className={styles.label}>
          Confirm Password:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="password"
            placeholder="Repeat Password"
            value={confirmPassword.value}
            onChange={e => setConfirmPassword({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${confirmPassword.feedback}`} >Passwords must match.</p>
        </Col>
      </Form.Group>
      <Button variant="primary" type='submit'>
        Save New Password
      </Button>
    </Form>
    <SnackBar />
  </Container>
}

const mapDispatchToProps = {
  triggerSnackBar
}

export default connect(null, mapDispatchToProps)(withRouter(PassResetPage))
