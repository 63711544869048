import React, { useEffect } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import CoverageMap from '../../components/coverage-map/coverage-map'
import styles from './edit-coverage.module.scss'
import TableRow from './table-row/table-row'
import Spinner from '../custom-spinner/custom-spinner'

import { connect } from 'react-redux'
import { fetchPlansAndCoverages, addCoverage, postCoverages  } from '../redux/actions'

interface Props {
  nrOfCoverages: number
  fetchPlansAndCoverages: any
  addCoverage: any
  postCoverages: any
  isLoading?: boolean
}

const EditCoverage: React.FC<Props> = ({nrOfCoverages, isLoading, fetchPlansAndCoverages, addCoverage, postCoverages}) => {

  useEffect(() => {
    fetchPlansAndCoverages()
  },[ fetchPlansAndCoverages ]);

  const renderTableBody = () => {
    const indexArr = []
    for ( let i = 0; i < nrOfCoverages; i++) {
      indexArr.push(i)
    }
    return (
      <tbody>
        {indexArr.map( i => <TableRow idx={i} key={i + Math.random()} />)}
      </tbody>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <h2 className={styles.title}>Edit Coverage Areas</h2>
      <Form >
        <CoverageMap className={styles.mainMap} options={{
          clickPolygon: true
        }}/>
        <p className={styles.mapDesc}>Click on an area to see its name.<br/><br/>Data plans with a status of SHOW = NO are grayed out, but can still be assigned to a coverage area. They will, however, not be included when searching for available plans.</p>
        <Table className={styles.table} striped bordered hover size="sm">
          <TableHeaders />
          {renderTableBody()}
        </Table>
        <div className={styles['btn-container']}>
          <Button variant="primary" onClick={addCoverage}>
            Add New Coverage
          </Button>
          <Button variant="success" onClick={postCoverages}
          >
            Save Data
          </Button>
        </div>
      </Form>
    </>
  )
}

const TableHeaders = () => {
  const editFields = ['Name', 'Show', 'Type', 'Source File', 'Delete']
  return (
    <thead className={styles.thead}>
    <tr className={styles.tr}>
      {editFields.map(f => (
        <th key={f} className={styles.th}>
          {f}
        </th>
      ))}
    </tr>
    </thead>
  )
}

const mapStateToProps = (state: any) => ({
  nrOfCoverages: state.nrOfCoverages,
  isLoading: state.coverageLoading
})

export default connect(mapStateToProps, { fetchPlansAndCoverages, addCoverage, postCoverages })(EditCoverage)
