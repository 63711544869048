import { MenuItem } from './types';
import { PricingPlan } from './types';
import React from 'react'
import Cookies from 'js-cookie'

console.log(process.env.NODE_ENV)
export const API_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
  'http://192.168.99.100:8080/api' :
  window.location.protocol + '//' + window.location.hostname
  + ( window.location.port !== '' ? ':' + window.location.port : '' ) + '/api';

export const SPEEDTEST_URL = "https://dev-www.airfiber.cc/api";



export const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + Cookies.get("token")
  },
  body: ''
}
//Title
export const TITLE: string = 'airfiber';

// PhoneNumber
export const PHONENUMBER: string = "623.526.6003";

//NavigationBar.tsx
export const MENUITEMS: MenuItem[] = [
  {
    name: "Contact",
    href: "/contact"
  }, {
    name: "Support",
    href: "/support",
    exact: true
  }, {
    name: "Coverage",
    href: "/coverage",
    exact: true
  }, {
    name: "Business",
    href: "/business",
    exact: true
  }, {
    name: "Residential",
    href: "/",
    exact: true
  }, {
    name: "Terms of Service",
    href: "/termofuse",
    exact: true
  }];

export const RESIDENTIALPLANS: PricingPlan[] = [
  {
    id: 1,
    downloadValue: 10,
    uploadValue: 5,
    price: 39.95
  },{
    id: 2,
    downloadValue: 25,
    uploadValue: 10,
    price: 59.95
  },{
    id: 3,
    downloadValue: 50,
    uploadValue: 20,
    price: 79.95
  },{
    id: 4,
    downloadValue: 100,
    uploadValue: 20,
    price: 99.95,
    optional: <div>*available in <span className="optional strong">certain areas</span> only,<br/> call us for availability</div>,
  }
];

export const BUSINESSPLANS: PricingPlan[] = [
  {
    id: 5,
    downloadValue: 25,
    uploadValue: 10,
    price: 79.95
  },{
    id: 6,
    downloadValue: 50,
    uploadValue: 25,
    price: 99.95
  },
  {
    id: 7,
    downloadValue: 100,
    uploadValue: 50,
    price: 149.95,
    optional: <div>*available in <span className="optional strong">certain areas</span> only,<br/> call us for availability</div>,
  },
  {
    id: 8,
    downloadValue: 100,
    uploadValue: 50,
    price: 149.95,
    optional: <div>*available in <span className="optional strong">certain areas</span> only,<br/> call us for availability</div>,
    upto: true
  }
]

export const center = {
  lat: 33.8017782,
  lng: -112.1301171
};
