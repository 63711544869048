export const SAVE_SETTINGS = {
    text: 'Settings have been saved.',
    duration: 3000,
    variant: 'success' // this is a react-bootstrap prop for <Button />
}

export const FORM_ERROR = {
    text: 'Please fill in all fields correctly.',
    duration: 3000,
    variant: 'danger'
}

export const PASS_RESET = {
    text:  'Password saved. Redirecting to login page...',
    duration: 5000,
    variant: 'success'
}

export const LOGIN_FAIL = {
    text: 'Unable to log in. Make sure you type the correct user and password.',
    duration: 5000,
    variant: 'danger'
}