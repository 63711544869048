export default (state = {text: '', duration: 0, visible: false, variant: null}, action) => {
    switch (action.type) {
        case 'TRIGGER_SNACK_BAR': return {
            ...action.payload,
            visible: true,
        }
        case 'HIDE_SNACK_BAR': return {
            ...state,
            visible: false
        }
        default: return state
    }
}