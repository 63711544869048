import { combineReducers } from 'redux';
import plansReducer from './plans-reducer';
import nrOfPlansReducer from './no-of-plans-reducer'
import coveragesReducer from './coverages-reducer';
import noOfCoveragesReducer from './no-of-coverages-reducer'
import feedbackReducer from './feedback-reducer'
import addressReducer from './address-reducer'
import markerPositionReducer from './marker-position-reducer'
import infoWindowTextReducer from './info-window-text-reducer'
import snackBarReducer from './snack-bar-reducer'
import isPlanDataLoading from './is-plan-data-loading'
import isCoverageDataLoading from './is-coverage-data-loading'

export default combineReducers({
	plans: plansReducer,
	nrOfPlans: nrOfPlansReducer,
	coverages: coveragesReducer,
	nrOfCoverages: noOfCoveragesReducer,
	feedback: feedbackReducer,
	address: addressReducer,
	markerPosition: markerPositionReducer,
	infoWindowText: infoWindowTextReducer,
	snackBar: snackBarReducer,
	planLoading: isPlanDataLoading,
	coverageLoading: isCoverageDataLoading
});
