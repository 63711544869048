import { API_URL } from '../../constants'
import Cookies from 'js-cookie'
import { CONFIRM_SAVE, SAVE_ERROR, LOAD_ERROR, SAVE_CONFLICT } from '../feedback-modal/feedback-messages'


export const fetchPlansAndCoverages = () => (dispatch) => {
  dispatch(fetchPlans())
  dispatch(fetchCoverages())
}

export const fetchPlans = () => async dispatch => {
  //   const response = await requestPlans() - TODO -> fix this to use request from service
  dispatch(setPlanDataLoading(true))
  const response = await fetch(API_URL + '/cms/plans')
  if ( !response.ok ) return dispatch ({
    type: 'OPEN_FEEDBACK',
    payload: LOAD_ERROR
  })
  const data = await response.json()
  dispatch({
    type: 'SET_PLANS',
    payload: data
  })
  dispatch({
    type: 'SET_PLANS_NR',
    payload: data.length
  })
  dispatch(setPlanDataLoading(false))
}

export const postPlans = () => async (dispatch, getState) => {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Cookies.get("token")
    },
    body: JSON.stringify(getState().plans)
  }
  const response = await fetch(API_URL + '/cms/plans', requestOptions)

  if (response.ok) dispatch({ type: 'OPEN_FEEDBACK', payload: CONFIRM_SAVE})
  else dispatch({ type: 'OPEN_FEEDBACK', payload: SAVE_ERROR})
}

export const addPlan = () => (dispatch) => {
  dispatch ({
      type: 'ADD_PLAN',
  })
  dispatch({
    type: 'INCREMENT_PLANS_NR',
  })
}

export const updatePlan = (idx, key, value) => ({
    type: 'UPDATE_PLAN',
    payload: {idx, key, value }
})

export const deletePlan = (id) => (dispatch, getState) => {
  const arrOfPlansArr = getState().coverages.map( c => c.plans)
  let plansArr = []
  arrOfPlansArr.forEach( arr => plansArr = [...plansArr, ...arr])
  const filteredPlansArr = [ ...new Set([...plansArr]) ]

  if (filteredPlansArr.includes(id)) return dispatch({
    type: 'OPEN_FEEDBACK',
    payload: SAVE_CONFLICT
  })
  dispatch({
      type: 'DELETE_PLAN',
      payload: { id: id}
  })
  dispatch({ type: 'DECREMENT_PLANS_NR',
  })
}

export const fetchCoverages = () => async dispatch => {
  const response = await fetch(API_URL + '/cms/coverage')
  dispatch(setCoverageDataLoading(true))
  if ( !response.ok ) return dispatch ({
    type: 'OPEN_FEEDBACK',
    payload: LOAD_ERROR
  })
  const data = await response.json()
  dispatch({
    type: 'SET_COVERAGES',
    payload: data
  })
  dispatch({
    type: 'SET_COVERAGES_NR',
    payload: data.length
  })
  dispatch(setCoverageDataLoading(false))
}

// TODO -> make this available from service
export const postCoverages = () => async (dispatch, getState) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Cookies.get("token")
    },
    body: JSON.stringify(getState().coverages)
  }
  const response = await fetch(API_URL + '/cms/coverage', requestOptions)
  
  if (response.ok) dispatch({ type: 'OPEN_FEEDBACK', payload: CONFIRM_SAVE})
  else dispatch({ type: 'OPEN_FEEDBACK', payload: SAVE_ERROR})
}

export const setCoverages = (coverages) => ({
  type: 'SET_COVERAGES',
  payload: coverages
})

export const addCoverage = () => (dispatch, getState) => {
  dispatch ({
      type: 'ADD_COVERAGE',
  })
  dispatch({
    type: 'INCREMENT_COVERAGES_NR',
    payload: getState().coverages.length + 1
  })
}

export const updateCoverage = (idx, key, value) => {
    return {
        type: 'UPDATE_COVERAGE',
        payload: {idx, key, value }
    }
}

export const deleteCoverage = (id) => (dispatch) => {
  dispatch({
      type: 'DELETE_COVERAGE',
      payload: { id: id}
  })
  dispatch({
    type: 'DECREMENT_COVERAGES_NR',
  })
}

export const setAddress = (address) => ({
  type: 'SET_ADDRESS',
  payload: address
})

export const setMarkerPosition = (position) => ({
  type: 'SET_MARKER_POSITION',
  payload: position
})

export const setInfoWindowText = (text) => ({
  type: 'SET_INFO_WINDOW_TEXT',
  payload: text
})

export const openFeedback = (message) => ({
  type: 'OPEN_FEEDBACK',
  payload: message
})

export const closeFeedback = () => ({
  type: 'CLOSE_FEEDBACK',
})

export const triggerSnackBar = (message) => ({
  type: 'TRIGGER_SNACK_BAR',
  payload: message
})

export const hideSnackBar = () => ({
  type: 'HIDE_SNACK_BAR',
})

export const setPlanDataLoading = (status) => ({
  type: 'SET_PLAN_DATA_LOADING',
  payload: status
})

export const setCoverageDataLoading = (status) => ({
  type: 'SET_COVERAGE_DATA_LOADING',
  payload: status
})
