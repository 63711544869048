export default (state = {isOpen: false, message:{}}, action) => {
    switch (action.type) {
        case 'OPEN_FEEDBACK': return {
            isOpen: true,
            message: action.payload
        }
        case 'CLOSE_FEEDBACK': return {
            isOpen: false,
            message: {}
        }
        default: return state
    }
}