import React, { FunctionComponent, useState } from 'react'
import { API_URL, SPEEDTEST_URL } from '../../constants'

const UPLOAD_SIZE = 20; // value in MB

export const SpeedTest: FunctionComponent = () => {

  const [dlSpeed, setDlSpeed] = useState('');
  const [upSpeed, setUpSpeed] = useState('');
  const [latency, setLatency] = useState('');
  const [totalTime, setTotalTime] = useState('');

  const testSpeed = () => {
    setLatency('');
    setTotalTime('');
    setUpSpeed('');
    setDlSpeed('');

    fetch(SPEEDTEST_URL + "/speedtest/ping").then(() => {
      let latency = 0;
      let pingCount = 0;
      let pingResCount = 0;
      let startPing = new Date().getTime();
      while (pingCount < 5) {
        fetch(SPEEDTEST_URL + "/speedtest/ping").then((res) => {
          console.log(res);
          let endPing = new Date().getTime();
          if (latency === 0 || endPing - startPing < latency) {
            latency = endPing - startPing;
          }
          if (pingResCount === 4) {
            setTimeout(() => {
              setLatency(latency + " ms");
            }, 1000);
            setDlSpeed('Pending ...')
            const sendData = generateObject(UPLOAD_SIZE);
            let start = new Date().getTime();
            const totalStart = new Date().getTime();
            fetch(SPEEDTEST_URL + "/speedtest/download")
              .then(data => data.blob())
              .then((res) => {
                if (res !== null) {
                  let size: any = res.size;
                  let end = new Date().getTime();
                  if (size !== null) {
                    setDlSpeed(Math.round((20971520 * 8) / 1024 / 1024 / ((end - start) / 1000)) + " Mbps")
                    setUpSpeed("Pending ...")
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-type': 'application/json'
                      },
                      body: JSON.stringify(sendData)
                    }
                    start = new Date().getTime();
                    fetch(SPEEDTEST_URL + "/speedtest/upload", options).then(() => {
                      end = new Date().getTime();
                      setUpSpeed(Math.round((UPLOAD_SIZE * 8) / ((end - start) / 1000)) + " Mbps")
                      setTotalTime((end - totalStart) / 1000 + " seconds")
                    });
                  }
                }
              })
          }
          pingResCount++;
        });
        pingCount++;
      }
    });
  }

  const generateObject = (size: number) => {
    console.log("generating " + size + " MB object");
    const obj: { data: string[]} = { "data" : []};
    let currentSize = 0;
    while (currentSize < size * 1024 * 1024) {
      obj.data.push(Math.random().toString(36).repeat(160000));
      currentSize = new TextEncoder().encode(JSON.stringify(obj)).length;
    }
    console.log(currentSize);
    return obj;
  }

  return <div className="mx-auto" style={{ width: "400px", marginTop: "100px" }}>
    <p>Latency : { latency} </p>
    <p>Download Speed : { dlSpeed }</p>
    <p>Upload Speed : { upSpeed }</p>
    <p>Total time of test : { totalTime }</p>
    <button onClick={testSpeed}>Test</button>
  </div>
}

export default SpeedTest;
