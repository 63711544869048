export const CONFIRM_SAVE = {
    title: 'Saved Changes',
    body: 'Your changes have been saved and are now available to view in the website.'
}

export const SAVE_ERROR = {
    title: 'Oooops...',
    body: 'Unfortunately there was an error when saving the data. Help is on the way!'
}

export const SAVE_CONFLICT = {
    title: 'Oooops...',
    body: 'The plan you are trying to delete is currently included in one of the coverage areas. Remove it from the coverage area first.'
}

export const LOAD_ERROR = {
    title: 'Oooops...',
    body: 'There seems to be a problem loading data right now. Did you try turning it off and on again?'
}

export const RESET_EMAIL = {
    title: 'Email Sent',
    body: 'An email has been set to the address you provided. It contains a link that you can use to reset your password.'
}

export const FILE_ERROR = {
    title: 'Upload Unsuccesfull',
    body: 'There might be an issue with the file you seleceted. Please check that the file extension is ".xml" or ".kml", and that the contents of the file are correct.'
}