import "./money-back.component.scss";

import React, { FunctionComponent } from "react";

export const MoneyBack: FunctionComponent = () => {
    return (<div className="horizontal-section">
        <div className="content money-back">
            <div className="day-term"><div className="day">30</div><div className="label">day</div></div>
            <div className="additional-text">
            money back <br/>
            guarantee service
            </div> 
        </div>
    </div>);
}