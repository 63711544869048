import React, { useEffect } from 'react'

import CoverageMap from '../../components/coverage-map/coverage-map'
import { connect } from 'react-redux'
import { fetchPlansAndCoverages } from '../../cms/redux/actions'
import AutocompletePlaces from '../../components/autocomplete-places/autocomplete-places'
import CoverageSearchResult from '../../components/coverage-search-result/coverage-search-result'

const  CoveragePage = (props: any) => {

  useEffect(() => {
    props.fetchPlansAndCoverages()
  },[]);


  return <div>
    <AutocompletePlaces />
    <CoverageSearchResult/>
    <CoverageMap className="coverage-map"
                 options={{
                   clickPolygon: false,
                   clickMap: true
                 }}
    />
  </div>
}


const mapStateToProps = (state: any) => ({
  nrOfCoverages: state.nrOfCoverages
})

export default connect(mapStateToProps, { fetchPlansAndCoverages })(CoveragePage)
