import "./section.component.scss";

import React, { FunctionComponent } from "react";

import { SectionPropTypes } from "./section.props";

export const Section: FunctionComponent<SectionPropTypes> = ({ items }) => {
    const itemMappedElements = items.map(({title, content, button, side, className}, index) => (
        <div className={`horizontal-section ${className || ""}`} key={index}>
            <div className="section">
           {title} {content} {button && <button className="cbtn primary">{button.label}</button>}
           </div>
           { side && (<div className="side">
               {side }
               </div>)}
       </div>)
   );
    return <div className="section">{ itemMappedElements }</div>;
}