
import * as Cookies from 'js-cookie'
import { API_URL } from '../constants'

export const AuthService = () => {
  const authenticate = async () => {
    let isLoggedIn = false;
    const token = Cookies.get("token");
    if (token !== undefined) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + token },
      };
      const data = await fetch(API_URL + '/cms/auth', requestOptions);
      const json = await data.json();
      isLoggedIn = json.result === "OK";
    }
    return isLoggedIn;
  }

  const login = async (username: string, password: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username, password: password })
    };
    const result = await fetch(API_URL + '/cms/login', requestOptions);
    const json = await result.json();
    if (json.auth === "OK") {
      const token = json.token;
      Cookies.set("token", token);
    }
  }

  const logout = () => {
    Cookies.remove("token");
  }

  return {
    authenticate,
    login,
    logout
  }
}