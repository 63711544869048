import React from 'react'
import {Button, Form} from 'react-bootstrap'
import Spinner from '../../custom-spinner/custom-spinner'

import type {Props} from './prop-types'
import styles from './table-row.module.scss'

import { connect } from 'react-redux'
import { updateCoverage, deleteCoverage } from '../../redux/actions'

const TableRow: React.FC<Props> = ({idx, coverage, updateCoverage, deleteCoverage, plansList}) => {
    const uploadText = coverage.poly ? 'Map File Is Loaded' : 'Upload New Map File'

    const renderCheckboxSet = (planType: any) => (
        <div className={styles.checkboxSet}>
            {plansList.filter(p => p.type === planType).map((p) => (
                <Form.Check key={p.id} className={`${styles.checkboxGroup} ${p.show === 'NO' ? styles.inactive : ''}`}>
                    <Form.Check.Input type='checkbox' className={styles.checkbox}
                        checked={coverage.plans.includes(p.id)}
                        onChange={ (e:any) => updateCoverage(idx, 'plans', p.id)}
                    />
                    <Form.Check.Label>{`${p.type}, Down ${p.downloadSpeed}, Up ${p.uploadSpeed}, $${p.price}`}</Form.Check.Label>
                </Form.Check>
            ))}
        </div>
    )
    
    if (!coverage) return <Spinner />

    return (
        <>
            <tr className={`${styles.tr} ${styles.topRow}`}>
                <td>
                    <Form.Control className={styles.input} size='sm' value={coverage.name}
                        onChange={(e) => updateCoverage(idx, "name", e.target.value)} 
                    />
                </td>
                <td>
                    <Form.Control className={styles.select} size='sm' as="select" value={coverage.show}
                                onChange={(e) => updateCoverage(idx, "show", e.target.value)}
                    >    
                    <option>NO</option>
                    <option>YES</option>
                    </Form.Control>
                </td>
                <td>
                    <Form.Control className={styles.select} size='sm' as="select" value={coverage.type}
                                onChange={(e) => updateCoverage(idx, "type", e.target.value)}
                    >    
                    <option>50</option>
                    <option>100</option>
                    </Form.Control>
                </td>
                <td className={styles.map}>
                    <Form.File label={uploadText} custom id={"fileElem" + coverage.id}
                        accept="application/vnd.google-earth.kml+xml" data-id={coverage.id}
                    />
                </td>
                <td className={styles.delete}>
                    <Button variant='danger' size='sm'
                        onClick={() => { deleteCoverage(coverage.id) }} 
                    >DELETE</Button>
                </td>
            </tr>
            <tr className={`${styles.tr} ${styles.bottomRow}`}>
                <td colSpan={5}>
                    <div className={styles.checkboxContainer}>
                        {renderCheckboxSet("BUSINESS")}
                        {renderCheckboxSet("RESIDENTIAL")}
                    </div>
                </td>
            </tr>
        </>
    )
}


const mapStateToProps = (state:any, ownProps:any) => {
    const coverage = state.coverages[ownProps.idx]
    return {
        coverage: coverage,
        plansList: state.plans
    }
}



export default connect(mapStateToProps, { updateCoverage, deleteCoverage })(TableRow) 
