import React, { useRef, useEffect } from 'react'
import convert from 'xml-js'
import { connect } from 'react-redux'
import { setCoverages, setAddress, setMarkerPosition, setInfoWindowText, openFeedback } from '../../cms/redux/actions'
import Spinner from '../../cms/custom-spinner/custom-spinner'
import { center } from '../../constants'
import { FILE_ERROR } from '../../cms/feedback-modal/feedback-messages'

interface Props {
  className?: string,
  setCoverages: any,
  coverages: any,
  setAddress: any,
  address: any
  options?: any,
  markerPosition: any,
  setMarkerPosition: any,
  infoWindowText: any,
  setInfoWindowText: any,
  updateAvailablePlans?: any,
  openFeedback?: any
}

const CoverageMap: React.FC<Props> = ({className, address, setAddress, coverages, setCoverages, options, markerPosition, setMarkerPosition, infoWindowText, setInfoWindowText, openFeedback}) => {

  const mapDiv = useRef(null)
  let map = useRef<google.maps.Map>();
  let infoWindow = useRef<any>();
  let marker = useRef<google.maps.Marker>();
  let mapEvent = useRef<google.maps.MapsEventListener>();
  let polygons: React.MutableRefObject<any[]> = useRef([]);

  const zoom = 11;
  const legend: Element = document.createElement('div');
  legend.innerHTML = '<div id="legend" class="map-legend">' +
    '<div class="map-legend-box green"></div><div class="small">Up to 50Mbps</div>' +
    '<div class="map-legend-box red"></div><div class="small">Up to 100Mbps</div>' +
    '</div>';

  useEffect(() => {
    const element = mapDiv.current
    if (element !== null) {
      map.current = new google.maps.Map(element, {
        center: center,
        controlSize: 24,
        zoom: zoom,
        mapTypeControl: true,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        streetViewControl: false
      });
      map.current.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
      if (options.clickMap) {
        mapEvent.current = map.current.addListener("click", setClickedLocation);
        marker.current = new google.maps.Marker({
          position: center,
          map: map.current,
        });
      }
    }
    infoWindow.current = new google.maps.InfoWindow();
    infoWindow.current.setContent(address !== '' ? address : 'AirFiber');
    infoWindow.current.open(map.current, marker.current);

    return () => {
      if (map.current !== undefined && mapEvent.current !== undefined) {
        google.maps.event.removeListener(mapEvent.current);
      }
    }
  },[]);

  useEffect(() => {
    if (markerPosition !== undefined && marker.current !== undefined && map.current !== undefined) {
      marker.current.setPosition(markerPosition);
      map.current.setCenter(markerPosition)
    }
  },[markerPosition])

  useEffect(() => {
    if (infoWindowText !== undefined && infoWindow.current !== undefined) {
      infoWindow.current.setContent(infoWindowText);
      infoWindow.current.open(map.current, marker.current);
    }
  },[infoWindowText])

  useEffect(() => {
    if (coverages !== undefined) {
      polygons.current.forEach(p => p.setMap(null));
      coverages.forEach((area: any) => {
        const fileElem: HTMLElement | null = document.getElementById("fileElem" + area.id);
        if (fileElem !== null) {
          fileElem.addEventListener("change", handleFile, false);
        }
        if (area.poly !== undefined && map.current !== undefined) {
          let polygon = new google.maps.Polygon(area.poly);
          polygon.setOptions({
            clickable: options.clickPolygon,
          });
          polygon.setMap(map.current);
          polygons.current.push(polygon);
          if (options.clickPolygon) {
            google.maps.event.addListener(polygon, 'click', function(event) {
              let contentString = area.name;
              infoWindow.current.setContent(contentString);
              infoWindow.current.setPosition(event.latLng);
              infoWindow.current.open(map.current);
            });
          }
        }
      });
      let polygonList = polygons.current;
      return () => {
        coverages.forEach((area:any) => {
          const fileElem: HTMLElement | null = document.getElementById("fileElem" + area.id);
          if (fileElem !== null) {
            fileElem.removeEventListener("change", handleFile, false);
          }
        });
        polygonList.forEach((polygon) => {
          polygon.setMap(null);
        })
      }
    }
  },[coverages])

  const isValidFile = (path: string):boolean => {
    const regEx = /[\\/][\w ]+.kml|[\\/][\w ]+.xml/
    return regEx.test(path)
  }

  function handleFile (e: any) {
    if (!isValidFile(e.target.value)) return openFeedback(FILE_ERROR)
    const zoneId:number = +e.target.getAttribute("data-id");
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      try {
        const xmlFile = atob(event.target.result.replace("data:application/octet-stream;base64,",""));
        const json = JSON.parse(convert.xml2json(xmlFile, {compact: true, spaces: 4}));
        const area = json.kml.Document.Placemark;
        const coordinatesText = area.LineString !== undefined ? area.LineString.coordinates._text : area.Polygon.outerBoundaryIs.LinearRing.coordinates._text;
        const coordinates = coordinatesText.split("\n").map((i: string) => {
          const [lng, lat] = i.split(",");
          return { lng: +lng.replace(/\s*/, ""), lat: +lat };
        }).filter((item: any) => item.lng !== 0 && item.lat !== 0);
        if (coverages !== undefined) {
          let newZones = [...coverages].map(zone => {
            if (zone.id === zoneId) {
              zone.poly = {
                paths: coordinates,
                strokeColor: "#000000",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: +zone.type === 100 ? "#FF0000" : "#33FF4E",
                fillOpacity: 0.35,
              }
            }
            return zone;
          });
          setCoverages(newZones);
        }
      } catch(e) {
        console.error(e)
        openFeedback(FILE_ERROR)
      }
    });
    reader.readAsDataURL(file);
  }

  function setClickedLocation(place: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { location: place.latLng },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === "OK") {
          if (results[0]) {
            setMarkerPosition(place.latLng);
            setInfoWindowText(results[0].formatted_address)
            setAddress(results[0].formatted_address);
          }
        }
      });
  }



  if (!coverages) return <Spinner />

  return (
    <div ref={mapDiv} className={className} />
  )
}

const mapStateToProps = (state: any) => ({
  coverages: state.coverages,
  address: state.address,
  markerPosition: state.markerPosition,
  infoWindowText: state.infoWindowText
})

export default connect(mapStateToProps, { setCoverages, setAddress, setMarkerPosition, setInfoWindowText, openFeedback })(CoverageMap)
