import { BrowserRouter } from 'react-router-dom'
import { CheckRouter } from './components/check-router/check-router.component'
import React from 'react';
import { Title } from './components/title/title.component';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import reducers from './cms/redux/reducers/combined-reducers'

const store = createStore(reducers, applyMiddleware(thunk, logger))

function App() {

    return(
      <Provider store={store}>
        <BrowserRouter>
            <Title/>
            <CheckRouter/>
        </BrowserRouter>
      </Provider>
    );

}

export default App;
