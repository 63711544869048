import './landingpage.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Footer } from '../../components/footer/footer.component';
import Logo from '../../components/logo/logo'
import { Menu } from '../../components/menu/menu.component'
import { MenuButton } from '../../components/menu-button/menu-button.component';
import { RouteMappedSections } from './route-mapped-sections/route-mapped-sections';
import { SectionsConfig } from './constants';

export const Landingpage = () => {
  const [offset, setOffset] = useState(0);
  const [isOpenMobileMenu, toggleMobileMenu] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const initialMove = useRef(0);


  const pageMaxOffset = Math.min( document.body.scrollHeight, document.body.offsetHeight
    , document.documentElement.scrollHeight - document.documentElement.clientHeight, document.documentElement.offsetHeight );
  const position = offset * 160 / (pageMaxOffset || 1) - 25;
  const calculateParallax = (e: Event) => {
    const pageOffset = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
    setOffset(pageOffset);
  }

  const trackMouseMovement = useCallback((e: any) => {
  const pageMaxOffsetLocally = Math.min( document.body.scrollHeight, document.body.offsetHeight
    , document.documentElement.scrollHeight - document.documentElement.clientHeight, document.documentElement.offsetHeight );
    const howMuchToMove =  e.clientY - initialMove.current;
    const absoluteScroll = howMuchToMove * pageMaxOffsetLocally / 160;
    window.scrollTo({top: absoluteScroll })
  }, [initialMove])

  const trackMouse = useCallback((e: any) => {
  const currentPosition = offset * 160 / (pageMaxOffset || 1) - 25;
    initialMove.current = lineRef.current?.offsetTop || 0 + currentPosition ;
    document.addEventListener("mousemove", trackMouseMovement);
  }, [ trackMouseMovement, initialMove])


  const unsetMouseTracking = useCallback(() => {
    document.removeEventListener("mousemove", trackMouseMovement);
  }, [ trackMouseMovement])


  useEffect(() => {
    const currentRef = divRef.current;
    window.addEventListener("scroll", calculateParallax);
    currentRef?.addEventListener("mousedown", trackMouse)
    document.addEventListener("mouseup", unsetMouseTracking);
    return () => {
      window.removeEventListener("scroll", calculateParallax);
      currentRef?.removeEventListener("mousedown", trackMouse)
      document.removeEventListener("mousemove", trackMouseMovement);
      document.removeEventListener("mouseup", unsetMouseTracking);
    };
  }, [divRef, trackMouse, unsetMouseTracking, trackMouseMovement]);
  useEffect(() => {
    if(isOpenMobileMenu){
      document.body.style.overflowY = "hidden";
    } else{
      document.body.style.overflowY = "auto";
    }
  }, [isOpenMobileMenu]);

  const trackInstantMovement = (e: any) => {
    const currentPosition = offset * 160 / (pageMaxOffset || 1) - 25;
    initialMove.current = lineRef.current?.offsetTop || 0 + currentPosition ;
    trackMouseMovement(e);
  }

  return (
    <>
      <div className="logo-background" style={{backgroundPositionX: offset * 0.015}}/>
      <div className='menu-position'>
            <Menu />
      </div>
      <div className={`right-section ${isOpenMobileMenu ? "toggled" : ""}`}>
        <div className="show-small mobile-section">
           <div className="logo-background mobile-background"/>
           <Menu onClick={() => toggleMobileMenu(false)}/>
        </div>
      </div>
      <div>
        <div className='logo-position'>
          <Logo />
          <MenuButton isOpen={isOpenMobileMenu} toggleClick={() => toggleMobileMenu(currentState => !currentState)}/>
        </div>
        <div className="left-section">
          <RouteMappedSections items={SectionsConfig}/>
          <Footer />
        </div>
      </div>
      <div className="track hide-small" ref={lineRef} onClick={trackInstantMovement}><div className="thumb" ref={divRef} style={{top: position}}></div></div>
    </>
  );
}