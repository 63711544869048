import React from 'react'
import graphic from './CMS-landing-page.svg'
import styles from './home-page.module.scss'

const HomePage = () => {
    return (
        <div className={styles.homePage}>
            <h3 className={styles.title}>Welcome to AirFiber's<br />Content Management System</h3>
            <img className={styles.image} src={graphic} />
            <p className={styles.text}>To start editing, simply click on one of the sections from the menu at the top. As you save changes, they will be instantly available to view on the website.</p>
        </div>
    )
}

export default HomePage
