import React from 'react';
import { TITLE } from '../../constants'
import { useLocation } from 'react-router-dom';

export const Title = () => {
  const { pathname } = useLocation();

  const pathName: string = pathname.replace('/', '');
  const firstPath: string = pathName.split('/')[0];


  if (firstPath === '') {
    return (
      <>
        <title>{TITLE} | residential</title>
      </>
    )
  } else {
    return (
      <>
        <title>{TITLE} | { firstPath }</title>
      </>
    )
  }
};