import React, { useEffect, useState } from 'react'
import { Button, Container, Col, Row, Form } from 'react-bootstrap'
import SnackBar from '../snack-bar'
import Spinner from '../custom-spinner/custom-spinner'
import { connect } from 'react-redux'
import { triggerSnackBar, openFeedback } from '../redux/actions'
import { FORM_ERROR, SAVE_SETTINGS } from '../snack-bar/snack-bar-messages'
import { SAVE_ERROR } from '../feedback-modal/feedback-messages'
import { API_URL, requestOptions } from '../../constants'
import styles from './settings-page.module.scss'

interface PropTypes {
  triggerSnackBar?: any
  openFeedback: any
}

const SettingsPage: React.FC<PropTypes> = ({triggerSnackBar, openFeedback}) => {
  const [oldPassword, setOldPassword] = useState({ value: '', feedback: '' })
  const [newPassword, setNewPassword] = useState({ value: '', feedback: '' })
  const [confirmPassword, setConfirmPassword] = useState({ value: '', feedback: '' })
  const [newEmail, setNewEmail] = useState({ value: '', feedback: '' })
  const [confirmEmail, setConfirmEmail] = useState({ value: '', feedback: '' })
  const [currentEmail, setCurrentEmail] = useState('')

  const [showSpinner, setShowSpinner] = useState(false)

  // const [rollbackDate, setRollbackDate] = useState('today')
  const currentData = {oldPassword, newPassword, confirmPassword, currentEmail, newEmail, confirmEmail}

  const emailRegex = /^\S+@\S+\.\S+$/

  useEffect(() => {
    if(currentEmail === '') {
      const options = {...requestOptions}
      options.method = 'get'
      delete options.body
      fetch(API_URL + '/cms/get-email', options)
        .then(res => res.json())
        .then(emailData => setCurrentEmail(emailData.email !== '' ? emailData.email : 'not set'))
    }
  })

  const isFormValid = () => {
    let hasValidationErr = false
    if (newPassword.value.length > 0 || confirmPassword.value.length > 0) {
      if (newPassword.value.length < 8) {
        setNewPassword({...newPassword, feedback: styles.visible})
        hasValidationErr = true
      } 
      
      if (confirmPassword.value !== newPassword.value) {
        setConfirmPassword({...confirmPassword, feedback: styles.visible})
        hasValidationErr = true
      }  
    }

    if (newEmail.value.length > 0 || confirmEmail.value.length > 0) {
      if (!emailRegex.test(newEmail.value)) {
        setNewEmail({...newEmail, feedback: styles.visible})
        hasValidationErr = true
      }  
  
      if (confirmEmail.value !== newEmail.value) {
        setConfirmEmail({...confirmEmail, feedback: styles.visible})
        hasValidationErr = true
      }  
    }  
    return !hasValidationErr
  }

  const clearForm = () => {
    setNewPassword({value: '', feedback: ''})
    setConfirmPassword({value: '', feedback: ''})
    setNewEmail({value: '', feedback: ''})
    setOldPassword({value: '', feedback: ''})
    setConfirmEmail({value: '', feedback: ''})
  }

  const submit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    if (!isFormValid()) return triggerSnackBar(FORM_ERROR)
    let settingsSaved = false
    if (oldPassword.value !== '' && newPassword.value !== '') {
      setShowSpinner(true)
      const passwordData = { oldPassword: oldPassword.value, newPassword: newPassword.value }
      const options = {...requestOptions}
      options.body = JSON.stringify(passwordData)
      const response = await fetch(API_URL + '/cms/change-password', options)
      // TODO -> add validation for entering correct password - need apropriate server response ??
      setShowSpinner(false)
      if (response.ok) settingsSaved = true 
      else openFeedback(SAVE_ERROR)
    }
    if (newEmail.value !== '') {
      setShowSpinner(true)
      const emailData = { email: newEmail.value }
      const options = {...requestOptions}
      options.body = JSON.stringify(emailData)
      const response = await fetch(API_URL + '/cms/change-email', options)
          if (response.ok) {
            settingsSaved = true
            setCurrentEmail(newEmail.value)
          } else openFeedback(SAVE_ERROR)
      setShowSpinner(false)
    }
    if (settingsSaved) triggerSnackBar(SAVE_SETTINGS)
    clearForm()
    // TODO -> if old password was not valid, trigger a snackbar and display form feedback
  }

  return (
    <Container className={`p-5 ${styles.loginPage}`}>
      {/*<Form.Group as={Row}>
        <Col sm={12}>
          <label className={styles.labelRollback}>Rollback to previously saved data. <span style={{fontSize: 10}}>*coming soon</span></label>
        </Col>
        <Form.Control className={styles.selectRollback} size='sm' as="select" value={rollbackDate}
        onChange={(e) => setRollbackDate(e.target.value)}
        >    
          <option>Sep, 9 2020</option>
          <option>Jan, 21 2021</option>
        </Form.Control>
      </Form.Group>
      <Row style={{width: '100%'}}>
        <Col sm={12} style={{width: '100%'}}>
          <div className={styles.spacing} />
        </Col>
      </Row>*/}
      <Form noValidate onSubmit={submit}>
      <Form.Group as={Row} className={styles.formGroup} controlId="oldPass">
        <Form.Label column sm="5" className={styles.label}>
          Old Password:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type='password'
            placeholder="Enter Current Password."
            value={oldPassword.value}
            onChange={e => setOldPassword({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${oldPassword.feedback}`} >Password was incorrect.</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className={styles.formGroup}  controlId="newPass">
        <Form.Label column sm="5" className={styles.label}>
          New Password:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="password"
            placeholder="Enter New Password."
            value={newPassword.value}
            onChange={e => setNewPassword({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${newPassword.feedback}`}>Password must be at least 8 characters long.</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className={styles.formGroup}  controlId="confirmPass">
        <Form.Label column sm="5" className={styles.label}>
          Confirm Password:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword.value}
            onChange={e => setConfirmPassword({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${confirmPassword.feedback}`} >Passwords must match.</p>
        </Col>
      </Form.Group>
      <Row style={{width: '100%'}}>
        <Col sm={12} style={{width: '100%'}}>
          <div className={styles.spacing} />
        </Col>
      </Row>
      <Form.Group as={Row} className={styles.formGroup} >
        <Form.Label column sm="5" className={styles.label}>
          Current Email:
        </Form.Label>
        <Form.Label column sm={7} className={styles.settingInfo}>
          { currentEmail }
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row} className={styles.formGroup}  controlId="newEmail">
        <Form.Label column sm="5" className={styles.label}>
          Change E-mail:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="email"
            placeholder="New Email"
            value={newEmail.value}
            onChange={e => setNewEmail({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${newEmail.feedback}`} >Email format not accepted.</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className={styles.formGroup}  controlId="confirmEmail">
        <Form.Label column sm="5" className={styles.label}>
          Confirm E-mail:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className={styles.input}
            type="email"
            placeholder="Confirm New Email"
            value={confirmEmail.value}
            onChange={e => setConfirmEmail({value: e.target.value, feedback: ''})}
          />
          <p className={`${styles.feedback} ${confirmEmail.feedback}`} >Emails must match.</p>
        </Col>
      </Form.Group>
      <Button className={styles.saveButton} variant="primary" type='submit'>
        Save Settings
      </Button>
      </Form>
      <Spinner isShown={showSpinner}/>
    </Container>
  )
}

const mapDispatchToProps = {
  triggerSnackBar,
  openFeedback
}

export default connect(null, mapDispatchToProps)(SettingsPage)
