import React from 'react';
import { ImagePropTypes } from './image.props';


export const Image = (props: ImagePropTypes) => {
    const {
      src,
      alt,
      className, 
     } = props

  return (
    <img className={ className } src={ src } alt={ alt } />
  );
}