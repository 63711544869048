import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";

import { RouteMappedSectionsPropTypes } from "./route-mapped-sections.props";
import { Section } from "../../../components/section/section.component";

export const RouteMappedSections: FunctionComponent<RouteMappedSectionsPropTypes> = ({ items }) =>  {
    const mappedRoutes =  items.map(({path, ...upperSectionProps}, index) => {
        return  <Route key={index} path={path} render={() => <Section {...upperSectionProps}/>} exact/>
     })
    return <Switch>{ mappedRoutes }</Switch>;
};