import "./menu-button.component.scss";

import React, { FunctionComponent } from "react";

export type MenuButtonPropTypes = {
    isOpen: boolean;
    toggleClick: () => void;
}

export const MenuButton: FunctionComponent<MenuButtonPropTypes> = ({isOpen, toggleClick}) => {
    return (<button className={`menu-cbtn ${isOpen ? "open" : ""}`} onClick={toggleClick}><div className="bars"></div></button>);
}