import React, { FunctionComponent } from 'react';
import { Button } from '../button/button.component';
import { useHistory } from 'react-router-dom';

interface GoBackPropTypes {
    className: string
    href:string
}

export const GoBack: FunctionComponent<GoBackPropTypes> = ({
    className,
    href
}) => {

    const { replace } = useHistory();

    const handleBackButtonClick = () => replace(href);

    return (
        <div className="cbtn-position">
            <Button className={className} value={"go back"} onClick={handleBackButtonClick} />
        </div>
    )
}