import './form.scss';

import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

export const ContactForm = () => {
  let formProps: any;

  const [{success, error, sending}, setState] = useState({success: false, error: false, sending: false});

  const path = useParams<{
    type:string,
    speed: string
  }>();
  const API_URL = `${window.location.protocol}//api-www.airfiber.cc/mail`;

  const contactFormPlans = (props: any) => {
    if (path.type && path.speed){
      return (
        <div className="contactForm">
           <Field id="additionalMessage" name="additionalMessage" placeholder="How did you hear about us" className={getErrorClass(props, "additionalMessage", true)}/>
           <Field id="referral" name="referral" placeholder="Referred by" className={getErrorClass(props, "referral", true)}/>
        </div>
      )
    }
  }

  const validateRequiredField = (value: string) => {
    if(!value) {
      return "Field is required";
    }
  }
  const validateEmail = (value: string) => {
    const regExp = new RegExp("[^@]+@[^\\.]+\\..+");
    if(!value || !regExp.test(value)) {
      return "Email doesn't match";
    }
  }
  
const getErrorClass = (props:  FormikProps<any>, field: string, isFull?: boolean) => {
  return props.getFieldMeta(field).error && props.getFieldMeta(field).touched ? isFull ? "invalid fullInput" : "invalid" : isFull ? "fullInput" : "";
}

const sendMessage = (formValues: any) => {
  setState({error: false, success: false, sending: true});
  return fetch(API_URL, {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': window.location.origin}
  }).then(res => {
    if(res.ok) {
      setState({error: false, success: true, sending: false});
      formProps.resetForm({
        firstName: '',
        lastName: '',
        email: '',
        phone:'',
        additionalMessage: '',
        referral: "",
        address: "",
        plan: `${path.type} - ${path.speed}`,
        message: ''
      });
    }
    else{
      setState({error: true, success: false, sending: false});

    }
  }).catch(() => {
    setState({error: true, success: false, sending: false});
  })
}


  return (

    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone:'',
        referral: '',
        address: "",
        additionalMessage: '',
        plan: `${path.type} - ${path.speed}`,
        message: ''
      }}
      onSubmit={sendMessage}
    >
      {(props: FormikProps<any>) => {
      formProps = props;
      return (<Form>
          <div className="contactForm">
          <div className="contactRows">
            {success && <div className="saved-successfully">The message was sent successfully. Thank you!</div>}
            {error && <div className="saved-error">There was an error when sending your message. Please try again later!</div>}
          </div>
          <div className="contactRows">
                <Field validate={validateRequiredField} id="firstName" name="firstName" placeholder="First name" className={getErrorClass(props, "firstName")}/>
                <Field validate={validateRequiredField} id="lastName" name="lastName" placeholder="Last name" className={getErrorClass(props, "lastName")}/>
              </div>
              <div className="contactRows">
                <Field validate={validateEmail} id="email" name="email" placeholder="Email" className={getErrorClass(props, "email")}/>
                <Field validate={validateRequiredField} id="phone" name="phone" placeholder="Phone" className={getErrorClass(props, "phone")}/>
              </div>
              {contactFormPlans(props)}
              <div className="contactForm">
              <Field validate={validateRequiredField} id="address" name="address" placeholder="Address" className={getErrorClass(props, "address", true)}/>
              <Field component="textarea" validate={validateRequiredField} id="message" name="message" placeholder="Type your message here" className={getErrorClass(props, "message")}/>
              <div className="submitContainer">
                <button type="submit" className="cbtn primary" disabled={sending}>{ sending ? "Sending your message" : "Submit" }</button>
                </div>
              </div>
        </div>

      </Form>)
      }
      }
    </Formik>

  )
}