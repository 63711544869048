import React from 'react'
import HeaderCMS from '../header-cms/header-cms'
import HomePage from '../home-page'
import { Container, Row, Col } from 'react-bootstrap/esm'
import styles from './layout-cms.module.scss'
import FeedbackModal from '../feedback-modal/feedback-modal'
import SnackBar from '../snack-bar'

const LayoutCMS: React.FC<any> = props => {

  return (
    <>
        <div className={styles.layout}>
          <HeaderCMS />
          <Container className={`${styles.container} table-responsive fixed-table-body`}>
            <Row>
              <Col>
                {props.children ? (
                  props.children
                ) : (
                  <div className={styles.div}>
                    <HomePage />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <FeedbackModal />
          <SnackBar />
        </div>
    </>
  )
}

export default LayoutCMS
