import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthService } from '../../cms/auth-service'


export const ProtectedRoute = (props: any) => {
    const Component: any = props.component;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const authService = AuthService();

    useEffect(() => {
        authService.authenticate().then((data: boolean) => {
            setIsAuthenticated(data);
            setIsLoading(false);
        });
    },[ authService ])


    return isLoading ? <>Loading</> : isAuthenticated ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    );
}