import { Button } from '../../components/button/button.component'
import { Cards } from '../cards/cards.component'
import { ContactForm } from '../form/form.component'
import CoveragePage from '../coverage-page/coverage-page'
import { MoneyBack } from './money-back/money-back.component'
import { Quote } from '../../components/quote/quote.component'
import React from 'react'
import { RouteMappedSectionItem } from './route-mapped-sections/route-mapped-sections.props'

export const SectionsConfig: RouteMappedSectionItem[] = [
  {
    path: '/',
    items: [
      {
        className: 'top-margin hide-side-small',
        title: <div className="title-big">Welcome to AirFiber </div>,
        content: (
          <div className="subtitle-big">
            our residential<span className="highlighted strong"> plans.</span>
          </div>
        ),
        side: (
          <div className="full-right center white-text">
            <div className="text-medium strong">For billing</div>
            <div className="text-medium">Log in to your account</div>
            <div className="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://billing.airfiber.cc">
                <Button
                  className="cbtn default spaced-button"
                  value={'log in'}
                />
              </a>
            </div>
          </div>
        )
      },
      {
         title: '',
         content: <Cards />
       },
      /*{
        title: '',
        content: <SidescrollWithCards />
      },*/
      {
        title: '',
        content: (
          <div className="text-extra-small">
            *All download and upload speeds may vary for reasons such as
            customer location, websites accessed, Internet congestion and
            customer equipment.
          </div>
        ),
        side: <div className="full-right center white-text"/>
      },
      {
        title: '',
        content: '',
        side: (
          <div className="full-right white-text right-aligned">
            <div className="subtitle-big strong">call today</div>
            <a href="tel:+16233633823" style={{color: 'inherit'}}>
              <div className="subtitle-big">623 363 3823</div>
            </a>
          </div>
        )
      },
      {
        title: '',
        content: '',
        side: <Quote left="empowering" right="internet connectivity." />
      },
      {
        title: (
          <div className="title-small uppercase">
            GET 1 MONTH FOR{' '}
            <span className="extra-strong highlighted">FREE</span>
          </div>
        ),
        content: (
          <div className="text-big bold responsive-margin-right">
            every time you refer a friend or neighbor
            <br />
            <span className="highlighted">unlimited</span> bandwidth
            <br />
            no yearly contracts
            <br />
            <span className="highlighted">SERVICE INSTALL</span> is{' '}
            <span className="highlighted">FREE</span>
          </div>
        ),
        side: ''
      },
      {
        className: 'mobile-vector one-month-vector',
        title: '',
        content: <MoneyBack />,
        side: <div className="picture money-back-background" />
      },
      {
        className: 'top-margin',
        title: <div className="title-medium">Why we are better?</div>,
        content: (
          <div className="text-big width-80">
            We eliminated the bundles, long-term contracts, hardware fees, and
            just left the good part: lightning-fast, incredibly reliable,
            awesome internet service at a fair price.
            <div className="center margin">
              <Button
                className="cbtn primary"
                value="check coverage area"
                to="/coverage"
              />
            </div>
          </div>
        ),
        side: (
          <div className="picture residential-background mobile-vector residential-vector" />
        )
      },
      {
        title: '',
        content: '',
        side: (
          <Quote
            className="top-margin"
            left="residential"
            right="plans for you."
          />
        )
      },
      {
        className: 'top-margin',
        title: <div className="title-medium">How it works?</div>,
        content: (
          <div className="text-big width-80">
            {' '}
            AirFiber is a different kind of internet service. We use
            next-generation technology to beam nothing but lightning-fast
            internet. And we’ve designed everything around the simple goal of
            making your internet life happier.
          </div>
        ),
        side: <div className="picture air-plans-background" />,
      },
      {
        className: 'top-margin ',
        title: (
          <div className="bottom-margin">
            <div className="title-medium">Get in touch</div>
            <div className="text-small">
              Send us a request and someone will respond you as soon as possible
            </div>
          </div>
        ),
        content: <ContactForm/>,
        side: (
          <>
            <div className="full-right right-aligned margin-top margin-bottom white-text">
              <div className="small-margin">
                <div className="text-small strong">
                  call for more information
                </div>
                <a href="tel:+16233633823">
                  <div className="subtitle-big a-small-display">
                    623 363 3823
                  </div>
                </a>
              </div>
              <div className="small-margin">
                <div className="text-small strong">address</div>
                <div className="text-medium">PO BOX 75185</div>
                <div className="text-medium">PHOENIX AZ 85087</div>
              </div>
            </div>
            <div className="picture contact-background top-margin" />
          </>
        )
      },
      {
        className: 'bottom-margin',
        title: '',
        content: '',
        side: (
          <Quote
            className="top-margin"
            left="contact us"
            right="for more informations"
          />
        )
      }
    ]
  },
  {
    path: '/business',
    items: [
      {
        className: 'top-margin',
        title: (
          <div className="title-big">
            Our <span className="highlighted strong">business</span> plans
          </div>
        ),
        content: '',
        side: (
          <div className="full-right white-text right-aligned">
            <div className="subtitle-big strong">call today</div>
            <a href="tel:+16233633823">
              <div className="subtitle-big">623 363 3823</div>
            </a>
          </div>
        )
      },
      {
         title: '',
         content: <Cards className="top-margin bottom-margin" isBusiness />
       },
      /*{
        title: '',
        content: <SidescrollWithCards isBusiness />
      },*/
      {
        title: '',
        content: (
          <div className="text-extra-small">
            *All download and upload speeds may vary for reasons such as
            customer location, websites accessed, Internet congestion and
            customer equipment.
          </div>
        ),
        side: <div className="full-right center white-text"/>
      },
      {
        title: '',
        content: '',
        side: <Quote left="empowering" right="internet connectivity." />
      },
      {
        className: 'top-margin',
        title: <div className="title-medium">How it works?</div>,
        content: (
          <>
            <div className="text-big width-80">
              AirFiber is a different kind of internet service. We use
              next-generation technology to beam nothing but lightning-fast
              internet. And we’ve designed everything around the simple goal of
              making your internet life happier.
            </div>
          </>
        ),
        side: (
          <>
            <div className="full-right right-aligned margin-top margin-bottom white-text">
              <div className="small-margin">
                <div className="text-small strong">
                  call for more information
                </div>
                <a href="tel:+16233633823">
                  <div className="subtitle-big a-small-display">
                    623 363 3823
                  </div>
                </a>
              </div>
              <div className="small-margin">
                <div className="text-small strong">address</div>
                <div className="text-medium">PO BOX 75185</div>
                <div className="text-medium">PHOENIX AZ 85087</div>
              </div>
            </div>
            <div className="picture business-background top-margin" />
          </>
        )
      },
      {
        className: 'top-margin',
        title: <div className="title-medium">Why we are better?</div>,
        content: (
          <>
            <div className="text-big width-80">
              We eliminated the bundles, long-term contracts, hardware fees, and
              just left the good part: lightning-fast, incredibly reliable,
              awesome internet service at a fair price.
            </div>
            <div className="full-right white-text top-margin">
              <Button
                className={'cbtn primary'}
                to={'/coverage'}
                value="check coverage area"
              />
            </div>
          </>
        )
      },
      {
        className: 'bottom-margin',
        title: '',
        content: '',
        side: (
          <Quote
            className="top-margin"
            left="residential"
            right="plans for you."
          />
        )
      }
    ]
  },
  {
    path: ['/contact', '/contact/:type/:speed'],
    items: [
      {
        className: 'top-margin bottom-margin',
        title: (
          <div className="bottom-margin">
            <div className="title-medium">Get in touch</div>
            <div className="text-small">
              Send us a request and someone will respond you as soon as possible
            </div>
          </div>
        ),
        content: <ContactForm />,
        side: (
          <>
            <div className="full-right right-aligned margin-top margin-bottom white-text">
              <div className="small-margin">
                <div className="text-small strong">
                  call for more information
                </div>
                <a href="tel:+16233633823">
                  <div className="text-medium a-small-display">
                    623 363 3823
                  </div>
                </a>

                <div className="text-small strong">technical support</div>
                <a href="tel:+16235266003">
                  <div className="text-medium a-small-display">
                    623 526 6003
                  </div>
                </a>
              </div>
              <div className="small-margin">
                <div className="text-small strong">address</div>
                <div className="text-medium">PO BOX 75185</div>
                <div className="text-medium">PHOENIX AZ 85087</div>
              </div>
            </div>
            <div className="picture contact-background top-margin" />
          </>
        )
      }
    ]
  },
  {
    path: '/coverage',
    items: [
      {
        title: (
          <div className="title-medium top-margin">
            {' '}
            Search in <span className="highlighted strong">your area</span>
          </div>
        ),
        side: <div className="picture air-plans-background" />,
        content: (
          <div style={{ fontSize: "1.7rem", fontWeight: 300}}>
            Type your address and see if you are in our coverage area
            <div className={'top-margin bottom-margin left-aligned'}>
              <CoveragePage />
            </div>
          </div>
        )
      }
    ]
  },
  {
    path: '/support',
    items: [
      {
        className: 'top-margin',
        side: (
          <div className="full-right white-text right-aligned">
            <div className="subtitle-medium strong">technical support</div>
            <a href="tel:+16235266003" style={{ color: '#ffffff' }}>
              <div className="subtitle-medium">623 526 6003</div>
            </a>
          </div>
        ),
        title: <div className="title-small">Support</div>,
        content: (
          <div className="text-medium width-80">
            In order to provide its customers with the highest level of service,
            AirFiber has adopted this Acceptable Use Policy (AUP). This Policy
            outlines acceptable use of the Service and is in addition to any
            other agreements in effect for the Service(s) customer has
            subscribed to.<div className="center margin"/>
          </div>
        )
      },
      {
        className: 'top-margin',
        side: <div className="picture business-background " />,
        title: <div className="title-small">Q &amp; A</div>,
        content: (
          <>
            <div className="title-small top-margin">
              How do I access my{' '}
              <span className="strong highlighted">email from the web?</span>
            </div>
            <div className="text-medium width-80 top-margin">
              If you already have free e-mail accounts (i.e., Yahoo!, MSN,
              Hotmail, Gmail, etc.) you can access these accounts over the
              AirFiber Internet connection.<div className="center margin"/>
            </div>

            <div className="title-small top-margin">
              Do you recommend{' '}
              <span className="strong highlighted">
                Virus Protection or Anti Spyware?
              </span>
            </div>
            <div className="text-medium width-80 top-margin">
              If you have not already done so, AirFiber highly recommends you
              install robust virus and anti spyware protection on your
              computer(s). Numerous software packages are available
              commercially. At a minimum we recommend you install a freeware
              package such as Microsoft Security Essentials.
              <div className="center margin"/>
            </div>

            <div className="title-small top-margin">Visit: </div>
            <div className="text-medium width-80 top-margin">
              Link for information{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://windows.microsoft.com/en‐US/windows/products/security‐essentials"
                style={{ color: '#0079CD' }}
                className="text-big width-80">
                (click here)
              </a>
              <div className="center margin"/>
            </div>
          </>
        )
      },
      {
        className: 'top-margin',
        side: <div className="picture question-background" />,
        title: (
          <div className="title-small">
            I have <span className="strong highlighted">no connection</span> to
            the Internet, or it is slow, how do I troubleshoot it?
          </div>
        ),
        content: (
          <>
            <div className="text-medium width-80 top-margin">
              The loss of your internet connection may happen for several
              reasons, but usually means that one or more of the devices on your
              home network has lost connectivity, or may need to be power
              cycled.<div className="center margin"/>
            </div>
            <div className="title-small top-margin">Check the following:</div>
            <div className="text-medium width-80 top-margin">
              Make sure the power adapter (also called Power over Ethernet Cable
              or Pigtail) for the roof antenna is plugged in and working. (This
              AC power supply typically has a white LED on it.)
              <div className="center margin top-margin"/>
            </div>
            <div className="text-medium width-80 top-margin">
              Make sure the physical network cables are connected to the wall
              plug, the computer and to any other network devices. Make sure all
              network devices are plugged in and have power.
            </div>
            <div className="text-medium width-80 top-margin">
              If this does not solve the issue do the following: Power cycle the
              roof antenna or “radio”, by unplugging the power adapter. Wait a
              minimum of 90 seconds and plug the device back in. NOTE: If the
              power adapter is plugged into a power strip, power cycle the power
              adapter itself not the power strip.
            </div>
            <div className="text-medium wdth-80 top-margin">
              If you have a router, wireless router or other network device,
              then power cycle the device(s) by unplugging the power. Wait a
              minimum of 90 seconds and plug the device back in. NOTE: If the
              router or other network device is plugged into a power strip,
              power cycle the router/device itself not the power strip.
            </div>
            <div className="text-medium width-80 top-margin">
              Make sure the Internet and network settings for your computer are
              correct and reboot your computer. If you are connecting wirelessly
              with a laptop, make sure the wireless feature is turned on, and
              you are in range of your wireless router.
            </div>
          </>
        )
      }
    ]
  },
  {
    path: '/termofuse',
    items: [
      {
        className: 'top-margin',
        side: <div className="full-right white-text right-aligned"/>,
        title:  <div className="title-small top-margin">
          Terms of Services &amp; Acceptable Use Policy (TOS &amp; AUP)
        </div>,
        content: (
          <>
            <div className="text-small width-80 top-margin">
              In order to provide its customers with the highest level of
              service, AirFiber WISP has adopted this Acceptable Use Policy
              (AUP). This Policy outlines acceptable use of the Service and is
              in addition to any other agreements in effect for the Service(s)
              customer has subscribed to.
            </div>
            <div className="text-small width-80 top-margin">
              Failure to comply with this AUP may result in the suspension or
              termination of your Service. If you do not agree to comply with
              this AUP, you must immediately stop use of the Service and notify
              AirFiber WISP, so that your account may be closed.
            </div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP reserves the right to make use of various
              technologies to prevent violation of any or all of this AUP.
            </div>
            <div className="text-small width-80 top-margin">
              We do not block access to any legal content, application, or
              service. We do not cap the amount of data you may use. We do not
              inspect and collect the contents of the data packets that transit
              through our network. We do not throttle specific content,
              applications, and services. We do not prohibit you from attaching
              non-harmful devices to your connection. We may take minor steps to
              manage our network.
            </div>
            <div className="text-small width-80 top-margin">
              Despite our efforts, we cannot guarantee any particular amount of
              bandwidth on the Service or that any speed or throughput of your
              connection will be available at all times. The speed of the
              Service will vary depending upon a number of factors, including
              your computer system(s) and associated equipment, Internet
              traffic, and other factors such as system capacity limitations,
              governmental actions, events beyond AirFiber WISP control, and
              system failures, modifications, upgrades, and repairs.
              Accordingly, we cannot guaranty that the Service will meet your
              requirements or expectations or will be uninterrupted, timely,
              secure, or error free.
            </div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP reserves the right to deny or suspend your service
              to any individual or organization which, in AirFiber WISP's
              opinion, intends to use the service in a manner that would violate
              this AUP.
            </div>
            <div className="title-small top-margin">
              Prohibited uses include using the Service and/or Equipment to:
            </div>
            <div className="text-small width-80 top-margin">
              1. Share files via Peer to Peer (P2P) networks. This includes, but
              is not limited to eMule, eDonkey, Kazaa, BitTorrent, Grokster,
              Gnutella and PeerCast. The transfer of any copyrighted materials
              will result in immediate termination.
            </div>
            <div className="text-small width-80 top-margin">
              2. Transmit or receive amounts of data exceeding what is typically
              considered reasonable for the Service, regardless of intent,
              purpose or knowledge. Excessive data transfer will result in
              restricted bandwidth.
            </div>
            <div className="text-small width-80 top-margin">
              3. Undertake or accomplish any unlawful purpose. This includes,
              but is not limited to, posting, storing, transmitting or
              disseminating information, data or material which is libelous,
              obscene, unlawful, threatening, defamatory, or which infringes the
              intellectual property rights of any person or entity, or which in
              any way constitutes or encourages conduct that would constitute a
              criminal offense, give rise to civil liability, or otherwise
              violate any local, state, federal or international law, order or
              regulation.
            </div>
            <div className="text-small width-80 top-margin">
              4. Restrict, inhibit, or otherwise interfere with the ability of
              any other person, regardless of intent, purpose or knowledge, to
              use or enjoy the Service, including, without limitation, posting
              or transmitting any information or software which contains a worm,
              virus, or other harmful feature, or generating levels of traffic
              sufficient to impede others' ability to send or retrieve
              information.
            </div>
            <div className="text-small width-80 top-margin">
              5. Resell the Service or otherwise make available to anyone
              outside the premises the ability to use the Service (i.e. Wi-Fi,
              or other methods of networking), in whole or in part, directly or
              indirectly, or on a bundled or unbundled basis.
            </div>
            <div className="text-small width-80 top-margin">
              6. Transmit bulk messages, commercial messages or "spam." This
              includes, but is not limited to, solicited or unsolicited
              advertising, promotional materials or other solicitation material,
              bulk mailing of commercial advertising, chain mail, informational
              announcements, charity requests, and petitions for signatures.{' '}
            </div>
            <div className="title-small top-margin">
              Open Internet Principles of Air Fiber WISP
            </div>
            <div className="text-small width-80 top-margin">
              The Federal Communications Commission issued rules to preserve the
              Internet as an open platform. These rules went into effect on
              November 23, 2011 and can be found at this link:
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gpo.gov/fdsys/pkg/FR-2011-09-23/pdf/2011-24259.pdf"
                style={{ color: '#0079CD' }}>
                (click here)
              </a>
            </div>
            <div className="text-small width-80 top-margin">
              All Internet service providers are required to post information
              regarding various issues so that consumers, both residential and
              business, can make informed choices about choosing an Internet
              service provider. This document contains information regarding our
              services and in compliance with the FCC’s rules. The policies
              contained herein serve as a supplement to the existing terms of
              service.
            </div>
            <div className="title-small top-margin">
              The FCC’s rules focus on three primary issues:
            </div>
            <div className="text-small width-80 top-margin">
              Transparency. Fixed and mobile broadband providers must disclose
              the network management practices, performance characteristics, and
              terms and conditions of their broadband services;
            </div>
            <div className="text-small width-80 top-margin">
              No blocking. Fixed broadband providers may not block lawful
              content, applications, services, or non-harmful devices; mobile
              broadband providers may not block lawful Web sites, or block
              applications that compete with their voice or video telephony
              services; and
            </div>
            <div className="text-small width-80 top-margin">
              No unreasonable discrimination. Fixed broadband providers may not
              unreasonably discriminate in transmitting lawful network traffic.
            </div>
            <div className="text-small width-80 top-margin">
              Reasonable network management. ISPs may engage in reasonable
              network management to maintain a high quality of service for
              broadband Internet access.
            </div>
            <div className="title-small top-margin">Network Practices</div>
            <div className="text-small width-80 top-margin">
              ISPs must disclose their network practices, specifically in the
              four general areas listed below. ISPs may not block lawful
              content, applications, services, or non-harmful devices, subject
              to reasonable network management. An ISP may not block consumers
              from accessing lawful Web sites, subject to reasonable network
              management; nor shall the ISP block applications that compete with
              the provider’s voice or video telephony services, subject to
              reasonable network management. ISPs may not unreasonably
              discriminate in transmitting lawful network traffic over a
              consumer’s broadband Internet access service, although, reasonable
              network management shall not constitute unreasonable
              discrimination. The FCC’s rules state that a network management
              practice is reasonable if it is appropriate and tailored to
              achieving a legitimate network management purpose, taking into
              account the particular network architecture and technology of the
              broadband Internet access service.
            </div>
            <div className="title-small top-margin">Congestion Management</div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP manages its network with one goal: to deliver the
              best possible broadband Internet experience to all of its
              customers.
            </div>
            <div className="title-small top-margin">
              Application-Specific Behavior
            </div>
            <div className="text-small width-80 top-margin">
              Bandwidth management is not based on application, content or
              protocol. The technique is “protocol-agnostic,” which means that
              the system does not manage congestion based on the applications
              being used by customers. It is also content neutral, so it does
              not depend on the type of content that is generating traffic
              congestion. Said another way, customer traffic is
              congestion-managed not based on the applications or content being
              used, but based on current bandwidth product the customer
              purchases.
            </div>
            <div className="title-small top-margin">
              Device Attachment Rules
            </div>
            <div className="text-small width-80 top-margin">
              Any device connected to the AirFiber WISP network must conform to
              publicly available industry standards and be non-harmful to the
              AirFIber WISP network. Residential subscribers may not run any
              type of server, including, but not limited to, DNS servers, mail
              servers, ftp servers, game servers or P2P servers.
            </div>
            <div className="title-small top-margin">Security</div>
            <div className="text-small width-80 top-margin">
              AirFIber WISP offers its customers unrestricted access to all of
              the lawful content, services, and applications available on the
              Internet. AirFIber WISP uses industry standard tools and best
              practices and policies to protect our customers from spam,
              phishing, and other unwanted or harmful on-line content and
              activities. In the instances where these tools and policies
              identify on-line content as harmful or unwanted, the content may
              be prevent from reaching customers, or customers may be permitted
              to identify or inspect content to determine if it is harmful or
              unwanted.
            </div>
            <div className="title-small top-margin">
              Performance Characteristics
            </div>
            <div className="text-small width-80 top-margin">
              ISPs must disclose the following network performance
              characteristics:
            </div>
            <div className="title-small top-margin">Service Description</div>
            <div className="text-small width-80 top-margin">
              The advertised speed of AirFiber WISP’s Internet Service is the
              maximum speed achievable with the technology utilized by AirFiber
              WISP. While the maximum advertised speed is attainable for end
              users, several factors may affect the actual speed of AirFiber
              WISP’s Internet service offerings, including, but not limited to:
              the end user’s computer, modem or router, activity during peak
              usage periods, and other Internet traffic. The service is provided
              on an “as is” and “as available” basis without warranties of any
              kind, either express or implied, including but not limited to
              warranties of title, non-infringement, implied warranties of
              merchantability or fitness for a particular purpose or any
              warranties arising from course of dealing or usage of trade, no
              advice or information given by AirFiber WISP, its affiliates, its
              licensers, its contractors or their respective employees shall
              create a warranty. Neither AirFiber WISP nor its affiliates, its
              licensers, its contractors or their respective employees warrants
              that the service will be uninterrupted or error free or that any
              information, software or other material accessible on the service
              is free of viruses, worms, Trojan horses or other malware and/or
              harmful components.
            </div>
            <div className="title-small top-margin">
              Impact of Specialized Services
            </div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP does not offer any specialized services at this time
              such as VoIP or IPTV.
            </div>
            <div className="title-small top-margin">Commercial Terms</div>
            <div className="text-small width-80 top-margin">
              ISPs must disclose the commercial terms of its broadband Internet
              access service including those listed below.
            </div>
            <div className="title-small top-margin">Pricing</div>
            <div className="text-small width-80 top-margin">
              In order to meet the usage and budgetary needs of all our
              customers, AirFiber WISP offers a wide selection of broadband
              Internet access plan options, including promotional offerings, and
              a la carte alternatives. To see AirFiber WISP’s current promotions
              and pricing on broadband Internet access service, please visit our
              website
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/"
                style={{ color: '#0079CD' }}>
                https://www.airfiber.cc/
              </a>
              or call{' '}
              <a href="tel:+16233633823" style={{ color: '#0079CD' }}>
                1-623-363-3823
              </a>{' '}
              For additional information on AirFiber WISP’s fee schedule for
              additional network services, visit the AirFiber WISP website at:
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/"
                style={{ color: '#0079CD' }}>
                https://www.airfiber.cc/
              </a>
              .
            </div>
            <div className="title-small top-margin">Privacy Policies</div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP affords full access to all lawful content, services
              and applications available on the Internet and does not routinely
              monitor, inspect or store the network activity and traffic of its
              Internet service users. However, AirFiber WISP reserves the right
              to monitor bandwidth, usage, transmissions and content for purpose
              of protecting the integrity of the network and AirFiber WISP’s
              Internet access service through reasonable network management
              practices.
            </div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP may collect equipment information to identify the
              equipment customer is using on the network, including, but not
              limited to: equipment type, serial number, settings, configuration
              and software. AirFiber WISP may also collect performance
              information to examine the operation of the equipment, services
              and applications the customer may use on the network, URLs, data
              transmission rates and latencies, location information, security
              characteristics, and information about the amount of bandwidth and
              other network resources customer is using in connection with
              uploading, downloading or streaming data to and from the Internet.
            </div>
            <div className="text-small width-80 top-margin">
              Network traffic, activity, performance information, and equipment
              information monitored or collected by AirFIber WISP is done for
              the sole purpose of reasonable network management purposes.
            </div>
            <div className="text-small width-80 top-margin">
              AirFiber WISP is required to comply with relevant laws,
              regulations and judicial orders. Information covered under this
              Privacy Policy, as well as other categories of information, may be
              disclosed to third parties if AirFiber WISP determines, in its
              sole discretion, that such a disclosure is necessary or required.
              AirFiber WISP may also disclose this information if, in its sole
              discretion, such a disclosure is necessary or required to protect
              our interests or the interests of our customers.
            </div>
            <div className="text-small width-80 top-margin">
              The AirFiber WISP network management practices as discussed herein
              are intended solely to provide the best on-line experience
              possible for our customers by safeguarding our network and its
              users from spam, viruses, phishing, and other unwanted or harmful
              on-line content and activities. AirFiber WISP’s network management
              practices are not intended, nor implemented, to block consumers
              from accessing the content and applications of their choice,
              deprive consumers of their entitlement to competition, or
              discriminate against or in favor of certain network traffic. For
              more information on AirFiber WISP privacy policies, please see the
              Privacy Policy section on our TOS (Terms of Service) page.
            </div>
            <div className="title-small top-margin">Redress Options</div>
            <div className="text-small width-80 top-margin">
              If you have questions regarding AirFiber WISP’s Network Management
              Practices Policy or would like to file a complaint with AirFiber
              WISP regarding its network management practices, please contact
              AirFiber WISP at:
            </div>
            <div className="text-small width-80 top-margin">AirFiber WISP</div>
            <div className="text-small width-80 ">
              34975 N. North Valley Pkwy (Bldg. 8, Ste. 152)
            </div>
            <div className="text-small width-80 ">Phoenix, AZ 85086</div>
            <div className="text-small width-80 ">
              Phone: 623-363-3823{' '}
              <a href="tel:+16233633823" style={{ color: '#0079CD' }}>
                (call here)
              </a>
            </div>
            <div className="text-small width-80 ">
              Email:{' '}
              <a href="mailto:office@airfiber.cc" style={{ color: '#0079CD' }}>
                office@airfiber.cc
              </a>
            </div>
            <div className="title-small top-margin">FCC Notice</div>,
            <div className="text-small width-80 top-margin">
              If a customer believes that these open Internet rules are not
              being met, the customer may file an informal complaint at the
              Federal Communications Commission. The FCC urges customers to
              submit any complaints via its website at the following address:
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://esupport.fcc.gov/complaints.htm"
                style={{ color: '#0079CD' }}>
                http://esupport.fcc.gov/complaints.htm
              </a>
              . Customers may also file a formal complaint at the FCC using Part
              76 of the Commission’s rules.
            </div>
            <div className="title-small top-margin">Additional Disclaimers</div>
            <div className="text-small width-80 bottom-margin">
              The Open Internet Rules, as adopted, and AirFiber WISP’s Network
              Management Practices Policy are not intended to affect, alter or
              otherwise supersede the legal status of cooperative efforts by
              broadband Internet Access Service Providers and other service
              providers that are designed to curtail infringement in response to
              information provided by rights holders in a manner that is timely,
              effective, and accommodates the legitimate interests of AirFiber
              WISP, rights holders, and end users. Furthermore, the Open
              Internet Rules, as adopted, and AirFiber WISP’s Network Management
              Practices Policy do not prohibit AirFiber WISP from making
              reasonable efforts to address the transfer of unlawful content or
              unlawful transfers of content. For additional information, please
              review AirFiber WISP’s Acceptable Use Policy on AirFiber WISP’s
              website.
            </div>
          </>
        )
      }
    ]
  }
]
