import './not-found.scss';
import Logo from '../logo/logo';
import React from 'react';
import { GoBack } from '../go-back/go-back.component';


export const NotFound = () => {

    return(
        <div className="not-found">
            <div className="left-container">
                <h2 className="title-big">ERROR</h2>
                <h1 className="text-medium">404</h1>
                <h3>The page you requested could not be found</h3>
                <div className="cbtn-position">
                    <GoBack className={"cbtn primary"} href={"/"}/>
                </div>
            </div>
            <div className="right-container">
                <div className="logo-position">
                    <Logo />
                </div>
                <img src="./notfound.svg" alt="Not Found"/>
            </div>
        </div>
      );
}