import React, { ReactElement, useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'

const transitionStyles:any = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

interface PropTypes {
    inProp?: boolean
    duration?: number
    children: ReactElement
}

const Fade: React.FC<PropTypes> = ({ inProp = true, duration = 500, children }) => {
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
      }

    return (
            <Transition in={inProp} timeout={duration} addEndListener={()=>{}}>
                {(state) => React.cloneElement(children, { style: {
                    ...defaultStyle,
                    ...transitionStyles[state]
                }})}
            </Transition>
    )
}

export default Fade