import React, { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button } from '../button/button.component'
import { Image } from '../image/image'
import styles from './card.module.scss'

export const Card = React.forwardRef<any, any>(
  (props, forwardedRef) => {

    const undDisc = useRef<any>()

    useEffect(() => {
      recursiveTransform(undDisc.current);
    })

    const recursiveTransform = (el: any) => {
      if (el.children[0] !== undefined) {
          el.children[0].style.transform = 'none'
          recursiveTransform(el.children[0]);
      }
    }

    const renderedPrice = (
      <div className={styles['undercard-price']} style={{ transform: 'none' }}>
        $ {props.price} <span className={styles.month}>/month</span>
      </div>
    )

    const missingPrice = (
      <div className={styles['undercard-price']} style={{ transform: 'none' }}>
        &nbsp;
      </div>
    )

    const scaleStyle = props.zoom === undefined ? {} : { transform: "scale(" + (props.zoom ? 1.1 : 1) + ")"}

    return (
      <div ref={forwardedRef} className={`${styles['data-plan-card']} ${props.className}`} style={scaleStyle}>
        <div className={styles['card-header']} style={{ transform: 'none' }}>up to</div>
        <div className={styles['card-body']} style={{ transform: 'none' }}>
          <div className={styles['number']} style={{ transform: 'none' }}>{props.downloadSpeed}</div>
          <h3 className={styles['text']} style={{ transform: 'none' }}>
            Mbps
            <br />
            download
            <br />
            speed <Image src="/i-flashlight.svg" className="icon" />
          </h3>
        </div>
        <p className={styles['card-footer']} style={{ transform: 'none' }}>{props.uploadSpeed} Mbps upload speed</p>
        <div className={styles['undercard']} style={{ transform: 'none' }}>
          <div className={styles['flex-center']} style={{ transform: 'none' }}>
           { props.price ? renderedPrice : missingPrice }
          </div>
          <div className={styles['flex-center']} style={{ transform: 'none' }}>
            <Button
              value={'order now'}
              className={`orderBtn ${styles['undercard-btn']}`}
              type={'button'}
              to={`/contact/${props.type}/${props.downloadSpeed}Mbps`}
            />
          </div>
          <div className={styles['flex-center']} style={{ transform: 'none' }}>
            <div className={styles['undercard-disclaimer']}  style={{ transform: 'none' }} ref={undDisc}>
              <ReactMarkdown source={props.disclaimer} escapeHtml={false} />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

 export default Card

 
