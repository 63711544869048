import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { AuthService } from '../auth-service'
import styles from './header-cms.module.scss'
import logo from '../../assets/svg/logo.svg'


const HeaderCMS: React.FC = () => {
  const authService = AuthService();

  return (
    <Navbar bg="light" expand="lg" fixed='top' className={styles.navbar}>
      <a href="/" target="_blank"><img alt="AirFiber Logo" src={logo} className={styles.logo}/></a>
      <NavLink to="/content-management">
        <Navbar.Brand>
          <p className={styles.title}>Content Management System</p>
        </Navbar.Brand>
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavLink to="/content-management/data-plans">
            <Nav.Item bsPrefix='nav-link'>Data Plans</Nav.Item>
          </NavLink>
          <NavLink to="/content-management/coverage">
            <Nav.Item bsPrefix='nav-link'>Coverage</Nav.Item>
          </NavLink>
          <NavLink to="/content-management/settings">
            <Nav.Item bsPrefix='nav-link'>Settings</Nav.Item>
          </NavLink>
          <NavLink to="/login" onClick={authService.logout}>
            <Nav.Item bsPrefix='nav-link'>Logout</Nav.Item>
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default HeaderCMS
