import React, { useEffect, useState } from 'react'
import { Cards } from '../../containers/cards/cards.component'
import { connect } from 'react-redux'
import { center } from '../../constants'

interface Props {
  markerPosition: any,
  address: any,
  coverages: any
}

const CoverageSearchResult: React.FC<Props> = ({ markerPosition, address, coverages }) => {

  const [availablePlans, setAvailablePlans] = useState<any>([]);
  const [isBusiness, setIsBusiness] = useState(false);

  useEffect(() => {
    if (address !== "" && markerPosition !== center) {
        let plans: any = [];
        if (coverages !== undefined) {
          coverages.forEach((coverage: any) => {
            if (coverage.poly !== undefined) {
              let poly = new google.maps.Polygon(coverage.poly);
              if (google.maps.geometry.poly.containsLocation(
                markerPosition,
                poly
              )) {
                plans = plans.concat(coverage.plans);
              }
            }
          });
        }
        setAvailablePlans(plans)
    }
  }, [markerPosition, address, coverages])

  return address === "" || markerPosition === center ? <></> : availablePlans.length > 0 ? <>
      <div style={{ fontSize: "2.2rem" }}>
        <span className="highlighted">Congratulations! </span>You are in
        <span className="highlighted"> our coverage</span> area.<br />
        Here are the {isBusiness ? 'business' : 'residential'} offers we can provide for you
        or <span className="highlighted hand" onClick={() => setIsBusiness(!isBusiness)}>click here for {isBusiness ? 'residential' : 'business'} offers</span>
      </div>
    <div style={{ paddingBottom: 20 }}>
      <Cards isBusiness={isBusiness} availablePlans={availablePlans} showDisclaimers={false} />
    </div>
    </>
      :
    <>
      <div>
        Unfortunately you are located in an area that we do not have coverage.<br /> You can call us and maybe we give you a solution.
      </div>
    </>
}

const mapStateToProps = (state: any) => ({
  markerPosition: state.markerPosition,
  address: state.address,
  coverages: state.coverages
})

export default connect(mapStateToProps)(CoverageSearchResult)