import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

import Spinner from '../../custom-spinner/custom-spinner'
import { connect } from 'react-redux'
import { updatePlan, deletePlan } from '../../redux/actions'
import styles from './table-row.module.scss'


interface Props {
    idx: any
    plan: any
    addPlan: any
    updatePlan: any
    deletePlan: any
}

const TableRow: React.FC<Props> = ({idx, plan, updatePlan, deletePlan}) => {
    const [visible, setVisible] = useState(false)
    const fadeInDelay = (idx+1) * 200

    const handleDelete = () => {
      // TODO -> implement fadeout AFTER confirmation that the plan is not used in coverages
      // this means that some checking logic must move from redux to component
      // replace 0 with animDuration after check implementation
      setTimeout(() => deletePlan(plan.id), 0)
    }

    useEffect(() => {
      const timeOut = setTimeout(() => {
        setVisible(true)
      }, fadeInDelay);
      return () => {
        clearTimeout(timeOut)
      }
    }, [])

    if (!plan) return <tr><td colSpan={6}><Spinner /></td></tr>

    return (
        <tr className={plan.show === "YES" ? styles.tr : `${styles.tr} ${styles.faded}`}>          
          <td><Form.Control className={styles.select} size='sm' as="select" value={plan.show} onChange={(e) => updatePlan(idx, "show", e.target.value)}>
              <option>NO</option>
              <option>YES</option>
            </Form.Control></td>
          <td>            
            <Form.Group>
              <Form.Control className={styles.input} size='sm' required value={plan.downloadSpeed} onChange={(e) => updatePlan(idx, "downloadSpeed", e.target.value)}/>
              <Form.Control.Feedback type="invalid">
                Please provide a download speed.
              </Form.Control.Feedback>
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control className={styles.input} size='sm' required value={plan.uploadSpeed} onChange={(e) => updatePlan(idx, "uploadSpeed", e.target.value)} />
              <Form.Control.Feedback type="invalid">
                  Please provide an upload speed.
                </Form.Control.Feedback>
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control className={styles.input} size='sm' value={plan.price} onChange={(e) => updatePlan(idx, "price", e.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide a price.
              </Form.Control.Feedback>
            </Form.Group>      
          </td>
          <td>
            <Form.Control className={styles.select} size='sm' as="select" value={plan.type} onChange={(e) => updatePlan(idx, "type", e.target.value)}>
              <option>BUSINESS</option>
              <option>RESIDENTIAL</option>
            </Form.Control>
          </td>
          <td>
            <Form.Control as='textarea' htmlSize={5} className={styles.textarea} size='sm' value={plan.disclaimer || ''}
                placeholder='Write Markdown or HTML.' onChange={(e) => updatePlan(idx, "disclaimer", e.target.value)}
            />
            <div className={styles.markdown}>
              <ReactMarkdown source={plan.disclaimer} escapeHtml={false}/>
            </div>
          </td>
          <td><Button className={styles.delete} variant='danger' size='sm' onClick={handleDelete}>DELETE</Button></td>
        </tr>
    )
}

const mapStateToProps = (state: any, ownProps: any) => ({
    plan: state.plans[ownProps.idx]
})

export default connect(mapStateToProps, {updatePlan, deletePlan })(TableRow)
