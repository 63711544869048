import './cards.scss'

import React, { useEffect, useRef, useState } from 'react'
import { Card } from '../../components/card/card.component'
import { CardsPropTypes } from './cards.props'
import { DataPlan } from '../../types'
import { API_URL } from '../../constants'

let plansPerPage = 0;
let firstWidth = 0;

export const Cards = (props: CardsPropTypes) => {
  const { isBusiness, availablePlans } = props
  const planType = isBusiness ? "BUSINESS" : "RESIDENTIAL"
  const [plansList, setPlansList] = useState<DataPlan[]>([])
  const cardsDiv = useRef<HTMLDivElement>(null)
  const linesDiv = useRef<HTMLDivElement>(null)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [currentLine, setCurrentLine] = useState(0)

  const handleLineClick = (i: number): void => {
    if (cardsDiv !== null && cardsDiv.current !== null) {
      const finalOrigin = i * 290 * plansPerPage ;
      cardsDiv.current.scrollTo(finalOrigin, 0);
    }
  }

  const handleDivScroll = () => {
    if (cardsDiv !== null && cardsDiv.current !== null) {
      setCurrentLine(cardsDiv.current.scrollLeft + 1 + plansPerPage * 290 > cardsDiv.current.scrollWidth ?
        numberOfLines - 1 : Math.round(cardsDiv.current.scrollLeft / (plansPerPage * 290)));
      //TODO scale mobile
    }
  }

  useEffect(() => {
    let cardsDivEl = cardsDiv.current;
    if (cardsDiv.current !== null)
      cardsDiv.current.addEventListener('scroll', handleDivScroll)
    return () => {
      if (cardsDivEl !== null)
      cardsDivEl.removeEventListener('scroll', handleDivScroll)
    }
  }, [numberOfLines])

  useEffect(() => {
    let newCardsDivWidth = window.innerWidth;
    if (cardsDiv !== null && cardsDiv.current !== null) {
      let cardsDivWidth = firstWidth === 0 ? cardsDiv.current.scrollWidth : firstWidth;
      newCardsDivWidth = Math.floor(cardsDivWidth / 290 ) * 290 ;
      cardsDiv.current.style.width = newCardsDivWidth + "px";
      if (firstWidth === 0) {
        firstWidth = newCardsDivWidth;
      }
      plansPerPage = newCardsDivWidth / 290;
    }
    fetch(API_URL + '/cms/plans')
      .then(res => res.json())
      .then((result) => {
          setPlansList(result);
          let nrPlans = [...result].filter( plan => plan.type === planType && plan.show === 'YES'
            && (availablePlans === undefined || availablePlans.indexOf(plan.id) !== -1)).length
          if ( nrPlans * 290 >= newCardsDivWidth && plansPerPage > 0) {
            let noOfLines = Math.ceil(nrPlans / plansPerPage)
            setNumberOfLines(noOfLines === 1 ? 0 : noOfLines)
            if (linesDiv !== null && linesDiv.current !== null)
            linesDiv.current.style.width = newCardsDivWidth + "px";
            if (cardsDiv !== null && cardsDiv.current !== null)
              cardsDiv.current.addEventListener('scroll', handleDivScroll)
          }
        },
        (error: any) => {
          console.log(error);
        })
  },[availablePlans, isBusiness])

  const renderedCards = plansList.filter(plan => plan.type === planType && plan.show === 'YES'
    && (availablePlans === undefined || availablePlans.indexOf(plan.id) !== -1))
    .map( (cardData, index) => (
      <Card
      key={index}
      downloadSpeed={cardData.downloadSpeed}
      uploadSpeed={cardData.uploadSpeed}
      price={cardData.price}
      type={cardData.type}
      disclaimer={props.showDisclaimers !== undefined && !props.showDisclaimers ? "" : cardData.disclaimer}
      zoom={plansPerPage === 1 ? index === currentLine : undefined}
    />
    ))

  return (
    <div className={`cardsList ${props.className || ''}`}>
      <div className="cards-wrapper" ref={cardsDiv}>
        <div className="cards">{renderedCards}</div>

      <div className="lines" ref={linesDiv}>
        {new Array(numberOfLines).fill({}).map((_data, index) => {
          return (
            <div
              key={index}
              onClick={() => handleLineClick(index)}
              className="touchable-line">
              <div
                className={`line ${index === currentLine && 'active'}`}
                style={{ width: index === currentLine ? 40 : 30 }}/>
            </div>
          )
        })}
      </div>
      </div>
    </div>
  )
}
