export default (state = [], action) => {
    switch (action.type) {
        case 'SET_PLANS': return action.payload
        case 'ADD_PLAN':
            let newId
            if ( state.length === 0) newId = 1
            else newId = state[state.length - 1].id + 1
            const newPlan = { id: newId, show:'NO', uploadSpeed: '', downloadSpeed: '', price: '', type: 'BUSINESS' }
            return [...state, newPlan]
        case 'UPDATE_PLAN': 
            const {idx, key, value} = action.payload
            state[idx] = {...state[idx], [key]: value}
            return [...state]
        case 'DELETE_PLAN':
            const { id } = action.payload
            return state.filter( c => c.id !== id)
        default: return state
    }

    
};
