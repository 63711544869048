import React, { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { hideSnackBar } from '../redux/actions'
import { CSSTransition } from 'react-transition-group'
import styles from './snack-bar.module.scss'

interface PropTypes {
    state: any
    hideSnackBar: any
}

export const SnackBar: React.FC<PropTypes> = ({ state, hideSnackBar }) => {
    const { text, duration, visible, variant } = state
    const timeoutID = useRef<NodeJS.Timeout>()

    useEffect(() => {
        if (visible) {
            if (timeoutID.current !== undefined) clearTimeout(timeoutID.current)
            timeoutID.current = setTimeout( () => hideSnackBar(), duration)
        }
    }, [state])

    return (
        <CSSTransition
            in={visible}
            addEndListener={() => {}} // typescript forces adding the addEndListener
            classNames={{...styles}}
        >  
            <Button className={styles.snackBar} variant={variant} size='sm'>{text}</Button>
        </CSSTransition>
    )
}

const mapStateToProps = (state:any) => ({
    state: state.snackBar
})

const mapDispatchToProps = {
    hideSnackBar
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar)
export * from './snack-bar-messages'
