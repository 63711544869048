import React, { MouseEvent, useEffect, useState } from 'react'
import { Button, Form, Row, Col, Container, Modal } from 'react-bootstrap'
import styles from './login-page.module.scss'
import { AuthService } from '../auth-service'
import { Redirect } from 'react-router-dom'
import { RESET_EMAIL } from '../feedback-modal/feedback-messages'
import { connect } from 'react-redux'
import { openFeedback, triggerSnackBar } from '../redux/actions'
import SnackBar from '../snack-bar'
import { LOGIN_FAIL, FORM_ERROR } from '../snack-bar/snack-bar-messages'
import FeedbackModal from '../feedback-modal/feedback-modal'
import logo from '../../assets/svg/logo.svg'
import { API_URL, requestOptions } from '../../constants'

interface Props {
  openFeedback: any
  triggerSnackBar: any
}

const LoginPage: React.FC<Props> = ({ openFeedback, triggerSnackBar }) => {

  const authService = AuthService();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const emptyInput = { value: '', feedback: '' }
  const [user, setUser] = useState(emptyInput)
  const [password, setPassword] = useState(emptyInput)
  const [email, setEmail] = useState(emptyInput)

  const [showEmailModal, setShowEmailModal] = useState(false)

  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/

  useEffect(() => {
    authService.authenticate().then((result: boolean) => setIsLoggedIn(result))
  },[ authService ])

  const isFormValid = () => {
    let hasValidationErr = false
    // TODO -> add validation for entering correct password

    if (!user.value) {
      setUser({...user, feedback: styles.visible})
      hasValidationErr = true
    } else setUser({...user, feedback: ''})
    
    if (password.value.length < 8) {
      setPassword({...password, feedback: styles.visible})
      hasValidationErr = true
    } else setPassword({...password, feedback: ''})
    return !hasValidationErr
  }

  const handleSubmit = () => {
    if (!isFormValid() ) return triggerSnackBar(FORM_ERROR)
    authService.login(user.value, password.value).then(() => authService.authenticate().then((result: boolean) => {
      if ( !result ) return triggerSnackBar(LOGIN_FAIL)
      setIsLoggedIn(result)
      
    }));
  }

  const handleForgotPass = (event: MouseEvent) => {
    event.preventDefault()

    if (!emailRegex.test(email.value)) {
      console.log('email validation failed')
      setEmail({...email, feedback: styles.visible})
      return
    } else setEmail({...email, feedback: ''})

    setShowEmailModal(false)
    const options = {...requestOptions}
    options.body = JSON.stringify({ email: email.value })
    fetch(API_URL + "/cms/reset-password", options)
    setEmail({value: '', feedback: ''})
    setTimeout(() => { 
      openFeedback(RESET_EMAIL)}, 1000)
  }

  if (isLoggedIn) return <Redirect to={{ pathname: '/content-management' }} />
  return (
    <Container className={`p-5 ${styles.loginPage}`}>
      <Row>
        <Col sm="12">
          <img alt='' src={logo} className={styles.logo}/>
        </Col>
      </Row>
      <Form.Group as={Row} controlId="username">
        <Form.Label column sm="4" className={styles.label}>
          Username:
        </Form.Label>
        <Col sm="8">
          <Form.Control className={styles.input} placeholder="Enter User" value={user.value} onChange={(e) => setUser({value: e.target.value, feedback:''})}/>
          <p className={`${styles.feedback} ${user.feedback}`} >Please enter a user name.</p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="password">
        <Form.Label column sm="4" className={styles.label}>
          Password:
        </Form.Label>
        <Col sm="8">
          <Form.Control className={styles.input} type="password" placeholder="Enter Password" value={password.value} onChange={(e) => setPassword({value: e.target.value, feedback:''})}/>
          <p className={`${styles.feedback} ${password.feedback}`} >Password must be at least 8 characters long.</p>
        </Col>
      </Form.Group>
      <Button className={styles.loginBtn} variant='primary' size='lg' onClick={handleSubmit}>Login</Button>
      <p className={styles.forgotPass}>Did you forget your password? <a className={styles.link} onClick={() => setShowEmailModal(true)}>Click Here.</a></p>
      <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter your email address and we  will send you a message with instructions on how to recover access to your account.</p>
            <Form.Group as={Row} controlId="password">
              <Form.Control className={styles.input} type="email" placeholder="Enter Email" value={email.value}
                onChange={(e) => setEmail({value: e.target.value, feedback:''})} />
              <p className={`${styles.emailFeedback} ${email.feedback}`} >Please enter a valid email.</p>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer> 
            <Button variant="primary" onClick={handleForgotPass} >Done</Button>
          </Modal.Footer>
      </Modal>
      <FeedbackModal />
      <SnackBar />
    </Container>
  )
}

export default connect(null, { openFeedback, triggerSnackBar })(LoginPage)
