import React, { useRef, useState, useEffect, createRef, useLayoutEffect } from 'react'
import styles from './side-scroll.module.scss'


// TODO -> make responsive
const SideScrollContainer = ({ children, style, cssClass, elClass }) => {
    const [elRefs, setElRefs] = useState([])
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    const scrollContRef = useRef(null)
    const elemsContainerRef = useRef(null)
    const btnsContRef = useRef(null)
    const elData = useRef([])
    const btnsRefs = useRef([])
    const container = useRef({})

    console.log('rendering... \n     children : ', children, '\n     elRefs: ', elRefs,
        '\n     elData: ', elData.current, '\n     container: ', container.current,
        '\n     btnsRefs: ', btnsRefs.current)

    // after mounting, an array of Refs is made, equal to the number of Component children
    useEffect(() => {   
        console.log('running "mounting" useEffect...')
        const containerSetup = { startPos: 0, endPos: scrollContRef.current.clientWidth, width: scrollContRef.current.clientWidth, totalWidth: 0 }
        container.current = containerSetup
        console.log('     setting container Ref: ', containerSetup)
    }, [])

    // two new Ref array is generated each time children prop changes
    useEffect(() => {   
        console.log('running "children" useEffect...')
        if (children.length < 1) return console.log('     doing nothing')
        console.log('     intializing elRefs as:', children.map(() => createRef()))
        setElRefs(children.map(() => createRef()))
        console.log('     intializing btnsRefs as:', children.map(() => createRef()))
        btnsRefs.current = children.map(() => createRef())
    }, [children])

    // after the Refs are created, and linked to children clones, the size of each rendered clone is computed
    useLayoutEffect(() => {   
        console.log('running "elRefs" useEffect...')

        if (elRefs.length < 1) return console.log('     doing nothing')  // only run code after refs have properly loaded

        const elSizeArr = elRefs.map( ref => {
            const elStyle = window.getComputedStyle(ref.current)
            return ref.current.clientWidth + parseFloat(elStyle.marginLeft) + parseFloat(elStyle.marginRight)
        })

        const elementsData = elSizeArr.map( (size, idx, arr) => {
            const startPos = idx === 0 ? 0 : arr.slice(0, idx).reduce( (acc, val) => acc + val)
            const endPos = startPos + size
            return { id: idx, startPos, endPos, midPoint: (startPos+endPos)/2, inView: false }
        })
        // setElData(elementsData)
        console.log('     setting elData as: ', elementsData)
        elData.current = elementsData
        console.log('     setting container totalWidth as: ', elementsData[elementsData.length - 1].endPos)
        container.current.totalWidth = elementsData[elementsData.length - 1].endPos
        setIsDataLoaded(true)
        if (container.current.totalWidth < container.current.width) {
            btnsContRef.current.style.display = 'none'
            scrollContRef.current.style.paddingBottom = 0
        }

    }, [elRefs])

    // after everything has loaded, a rerender is trigger by setting isDataLoaded to true
    // and this effect will animate the buttons accordingly
    useEffect(() => {
        console.log('running "isDataLoaded" useEffect...')
        if (btnsRefs.current.length < 1 ) return console.log('     doing nothing')
        console.log('     triggering handleClickScroll(0)')
        handleClickScroll(0)
    }, [isDataLoaded])

    const handleClickScroll = (id) => {
        changeButtons(id)
        const pos = elData.current[id].midPoint - (container.current.width / 2)
        elemsContainerRef.current.setAttribute('style',
        `transform: translateX(-${Math.max(Math.min(pos, container.current.totalWidth - container.current.width), 0)}px);`
        )
    }

    const changeButtons = (id) => { // TODO -> this should adjust busttons with css classes
        const elViewSt = elData.current[id].midPoint - (container.current.width / 2)
        const elViewEnd = elViewSt + container.current.width
        const [viewStart, viewEnd] = elViewSt < 0 ? [0, container.current.width] 
            : elViewEnd > container.current.totalWidth ? [container.current.totalWidth - container.current.width, container.current.totalWidth]
            : [elViewSt, elViewEnd]
        // console.log('viewStart: ', viewStart)
        // console.log('viewEnd: ', viewEnd)
        elData.current.forEach( (el, idx) => {
            // console.log('el.startPos: ', el.startPos)
            // console.log('el.endPos: ', el.endPos)

            if (el.startPos >= viewStart && el.endPos <= viewEnd) btnsRefs.current[idx].current.classList.add(styles.inView)
            else btnsRefs.current[idx].current.classList.remove(styles.inView)
        })
    }

    return (
        <div ref={scrollContRef} className={`${styles.scrollContainer} ${cssClass}`} style={style}>
            <div ref={elemsContainerRef} className={styles.elementsContainer}>
                {children.map((el, i) => {                    
                    const newEl = React.cloneElement(el, { ref: elRefs[i], key: i, style: { postion: 'relative'}, className: elClass})
                    // console.log('cloned element with props and ref: ', newEl)
                    return newEl
                })}
            </div>
            <div ref={btnsContRef} className={styles.buttonsContainer}>
                {children.map( (_, idx) => (
                <div key={idx} ref={btnsRefs.current[idx]} className={styles.button}
                    onClick={() => handleClickScroll(idx)}
                />
                ))}
            </div>
        </div>
    )
}

export default SideScrollContainer