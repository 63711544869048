// !!! for development only
import React, { useRef, useState, useEffect, createRef } from 'react'
import Fade from '../animation/fade/fade'
import { Transition } from 'react-transition-group'
import SideScroll from '../../containers/side-scroll'
import Card from '../../components/card/card.component'
import styles from './test-page.module.scss'
import { API_URL } from '../../constants'




const TestPage = (props) => {
  const [visible, setVisible] = useState(false)
  const [plansList, setPlansList] = useState([])
  const [cardComponents, setCardComponents] = useState([])
  const [isBusiness, setIsBusiness] = useState(false)


  
  const planType = isBusiness ? "BUSINESS" : "RESIDENTIAL"

  useEffect(() => {
    fetch(API_URL + '/cms/plans')
      .then(res => res.json())
      .then((result) => {
        // console.log('fetch response is:', result)
        const renderedCards = result.filter( plan =>
          plan.type === planType
          && plan.show === 'YES'
          // && (availablePlans === undefined || availablePlans.indexOf(plan.id) !== -1)
        ).map(
          (cardData, index) => (
            <Card
              key={index}
              downloadSpeed={cardData.downloadSpeed}
              uploadSpeed={cardData.uploadSpeed}
              price={cardData.price}
              type={cardData.type}
              disclaimer={cardData.disclaimer}
            />
          )
        )
        // console.log('renderedCards equates to:', renderedCards)
        setCardComponents(renderedCards)
      },
      (error) => {
        console.log(error);
      })
  },[])

  // console.log('test-page is rendering with: ', cardComponents )
  return (
    <div>
      <button
        onClick={() => {
          setVisible(!visible)
        }}>
        animate
      </button>
      <button
        onClick={() => {
          setIsBusiness(!isBusiness)
        }}>
        switch cards
      </button>
      <Fade duration={2000}>
        <div>i am a Fade with just a duration prop</div>
      </Fade>
      <Fade inProp={visible} duration={2000}>
        <div>i am Fade, my in prop is toggled with a button</div>
      </Fade>
      <Transition in addEndListener={() => {}}>
        <div>I am a Transition element from react-transition-group</div>
      </Transition>
      <SideScroll style={{width: '700px', height: '500px'}}>
        {cardComponents}        
      </SideScroll>
      {/* <SideScroll style={{width: '500px', height: '500px'}}>
        <p className={styles.containedElTypeA}></p>
        <div className={styles.containedElTypeB}></div>
        <div className={styles.containedElTypeA}></div>
        <a className={styles.containedElTypeB}></a>
        <div className={styles.containedElTypeB}></div>
        <div className={styles.containedElTypeA}></div>
        <div className={styles.containedElTypeA}></div>        
      </SideScroll> */}
    </div>
  )
}

export default TestPage
