import './button.scss';

import React, { FunctionComponent } from 'react';

import { ButtonPropTypes } from './button.props';
import { useHistory } from 'react-router-dom';

export const Button: FunctionComponent<ButtonPropTypes> = ({
      onClick,
      value,
      className,
      type,
      to,
      disabled
    }) => {
      const {push} = useHistory();
      const handleClick = () => {
        onClick && onClick();
        if(to) {
          push(to);
        }
      }
      return (
        <button onClick={ handleClick } className={ className } type={ type } disabled={disabled}  style={{ transform: 'none' }}> {value} </button>
      );
  }